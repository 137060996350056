@import "../../Styles/variables";
@import "../../Styles/mixin";
.budget-sources-section {
  .budget-sources-block {
    background: #f4f4f4;
    padding: 24px;
    min-height: 80vh;
    position: relative;
  }

  .project-section {
    cursor: pointer;

    input {
      margin: 3px;
      height: unset;
      width: unset;
    }

    .check-button {
      align-items: baseline;
    }
    span {
      font-weight: 600;
      font-size: 13px;
      text-align: initial;
      margin-left: 8px;
    }
  }
  .title-block {
    @include d-center;
    background: $lightBlue;
    width: 100%;
    padding: 7px;
    .title {
      color: $secondaryColor;
    }
  }

  .interested-block {
    .title {
      margin-bottom: 20px;
    }
  }
  .btn-block-right {
    position: absolute;
    bottom: 24px;
    right: 24px;
  }
  .btn-section {
    margin-top: 12px;
    padding: 12px 0;
    border-top: 1px solid #f1f1f1;
    background: #f4f4f4;
  }
}
