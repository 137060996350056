

@import "../../Styles/variables";

.pricing-shimmer-section{
    margin-top:20px;
    .shimmer-card{
        background-color: #4f4f4f0f;
        border-radius: 15px;
        padding: 20px;
        min-height: 450px;
        width: 100%;
        height: 521px;
    }
    p{
        width: 100px;
        background-color: #fff;
        padding: 9px;
        margin: 10px 0;
        border-radius: 5px;

    }

    .border{
     margin: 20px 0;

    }

    .section-margin{
        margin-bottom: 50px;
    }

    .d-flex{
        margin-bottom: 20px;
        .icon{
            padding: 8px;
            background-color: $white;
            width: 10%;
            border-radius: 5px;
    
        }
        .content{
            background-color: $white;
            margin-left: 10px;
            padding: 8px;
            width: 45%;
            border-radius: 5px;
           
        }
    }
}