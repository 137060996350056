@import "../../Styles/variables";

.new-publisher-banner {
  position: relative;
  background-color: $secondaryColor;
  color: #fff;
  border-radius: 0;
  .company-details-section {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    height: 100%;
    .logo-section {
      min-width: 100px;
      height: 100px;
      background-color: #fff;
      padding: 4px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      img {
        width: 100%;
      }
    }
    .company-details {
      .block-title {
        margin-bottom: 0;
      }
      img {
        width: 24px;
        height: 24px;
      }
      .company-detail-content {
        font-size: 14px;
        line-height: 24px;
      }
      .banner-img {
        height: 28px;
        width: auto;
        margin-left: 8px;
      }
    }
  }
  .dropdown {
    display: flex;
    height: 100%;
    align-items: end;
    justify-content: flex-end;
  }

  .btn-dropdown {
    .btn-secondary {
      border: 1px solid transparent;
      color: $white !important;
      background: $primary-gradient;
      border-radius: 5px;
      margin: 0px 12px 0px 12px;
      border: 1px solid #fff;

      &:focus {
        box-shadow: none;
      }
    }

    .dropdown-toggle::after {
      position: unset;
      color: white;
      margin-left: 5px;
    }

    .dropdown-menu {
      padding: 0;
      min-width: 220px;

      .menu-title {
        padding: 6px 15px;
        font-size: 15px;
        font-weight: 500;
        border-bottom: 1px solid $borderColor;
        cursor: pointer;
        p {
          font-size: 10px;
          font-weight: 500;
          color: $fieldColor;
        }
        &:hover {
          background: $primary-gradient;
          color: $white;

          p {
            color: $white;
          }
        }

        :last-child {
          border: none;
        }
      }
    }
  }
}
