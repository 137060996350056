@import "../../Styles/variables";

.fees-section {
  letter-spacing: 0.02em;
  font-size: 16px;

  .info-flex {
    display: flex;
    justify-content: initial;

    .mdi-information {
      width: 20px;
      color: $yellow;
      position: absolute;
      margin-top: 8px;
      margin-left: 18px;
    }

    .info-content {
      background: $lightPinkColor;
      color: $yellow;
      padding: 8px;
      padding-left: 52px;
      margin-bottom: 38px;
      font-size: 14px;
    }
  }

  .pending {
    display: flex;
    gap: 1rem;
  }

  .mg-top-45 {
    margin-top: 45px;
  }

  .detail-content {
    text-align: left;
    font-weight: bold;
  }

  .btn {
    margin-top: 0;
  }

  .content-left {
    font-weight: 600;
    margin-top: 8px;
    text-align: left;
  }

  .position {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    align-items: center;

    .primary-btn {
      padding: 8px 16px;
    }

    .delete-btn {
      padding: 8px 16px;
    }
  }

  .boq-bill {
    border: 1px solid $colorBorder;
    padding: 16px;
  }

  .img-icon {
    display: flex;

    .icon-block {
      margin: 10px 0;
      padding: 8px;
      background: $white;
      border: 1px solid $borderColor;
      box-sizing: border-box;
      border-radius: 5px;
    }

    .icon-block :hover {
      cursor: pointer;
    }

    .icon-block:active {
      padding: 6px;
    }

    .icon-spacing {
      margin-right: 10px;
    }
  }

  .drop-border {
    border: 1px dashed $inputBorder;
    padding: 30px;
    background: $imgBackground;
    border-radius: 8px;
  }

  .drop-zone {
    .inline-block {
      display: inline;
    }

    .drop-icon {
      img {
        width: 30px;
      }

      .dzu-dropzone {
        background-color: $red;
        margin-bottom: 20px;
        overflow: hidden;
        color: $contentColor;
        font-weight: 500;
        font-size: 14px;
        padding-bottom: 24px;

        .dzu-icon {
          padding: 32px 0 18px 0;
        }

        span {
          text-decoration: underline;
          color: $borderColor;
        }
      }
    }
  }

  .upload-venture {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    color: $textColor;
    width: 50%;
  }

  .file-uploadimg {
    img {
      width: 33px;
      height: 33px;
      margin-bottom: 19px;
    }
  }

  .drag-file {
    color: #8098a5;
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0.25px;
  }

  .venture-img {
    text-align: center !important;
  }

  .table-first {
    .table tr {
      width: 70%;
    }
  }

  .sub-row {
    align-items: center;
    border: 1px solid #ebebeb;
    display: flex;
    justify-content: space-between;
    background: $bodyColor;
    border-bottom: 1px;
  }

  .table thead th {
    border-bottom: unset;
  }

  .fees-section .table thead th {
    border-bottom: unset;
  }

  .table td,
  .table th {
    border-top: unset;
  }

  .sub-topic {
    font-weight: 600;
  }

  .sub-row last {
    font-weight: 700;
  }

  tr:nth-child(odd) {
    background: $bodyColor;
  }

  tr:nth-child(even) {
    background: $white;
  }

  .sub-num {
    font-weight: normal;
  }
}

.pay-security-fee {
  border-top: 1px solid $borderColor;
  padding-top: 15px;

  .second-content {
    margin-bottom: 8px;
  }
}

.payment-content-field {
  .border-line {
    border-bottom: 1px solid $borderColor;
  }

  .security-fee-second {
    .pink-table {
      border-bottom: 1px solid aliceblue;
      background: rgba(164, 6, 6, 0.1);
    }
  }

  .security-fee-third {
    background-color: white !important;
  }
}


.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.04);
  z-index: 1000;
  top: 0;
  left: 0;
  border-radius: 5px;
  cursor: no-drop;
}