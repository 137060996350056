@import "../../Styles/variables";

.joint-venture {
  position: relative;

  .border-line {
    border-left: 1px solid $yellow;
  }
  .save-next {
    margin: 20px 0px;
  }
  .position-end {
    justify-content: end;
    display: flex;
    margin-top: 10px;
  }
  .venture-content {
    background: $lightPinkColor;
    color: $yellow;
    padding: 8px;
    padding-left: 52px;
    margin-bottom: 38px;
  }

  .mdi-plus {
    width: 17px;
    margin-right: 10px;
  }

  .medium-content {
    border: 1px solid $secondaryColor;
    background: white;
    color: $secondaryColor;
    padding: 7px 19px;
    border-radius: 5px;
    margin-bottom: 32px;
  }

   .btn-cancel {
      padding: 8px 20px;
      background: $secondaryColor;
      color: $white;
      border: 1px solid $secondaryColor;
      border-radius: 5px;
      letter-spacing: 0.07em;
      margin-right: 12px;
    }

  .boq-bill {
    border: 1px solid $colorBorder;
    padding: 16px;

    .info-flex {
      display: flex;
      justify-content: initial;

      .border-line {
        height: 35px;
      }
    }
  }
}
