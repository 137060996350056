@import "../../Styles/variables";
.icon-size {
  width: 1rem;
}
.icon-size:hover {
  color: darkgray;
}
.bidder-wrapper {
  .excel-download {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    gap: 2rem;
    input {
      width: fit-content;

      &:focus {
        box-shadow: none;
      }
    }

    .btn {
      font-size: 14px;
    }

    .download-btn {
      padding: 6px 8px;
      i {
        margin-right: 8px;
      }
    }
  }
  .content {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    background-color: #f9f9f9;
    text-transform: capitalize;
  }

  .yellow-highlight {
    background-color: #fcf2dc;
    color: #b0800e;
    margin: 10px auto;
    display: flex;
    align-items: center;
    padding: 3px 8px;
    border-radius: 2px;
    width: fit-content;
  }

  .green-highlight {
    background-color: #e5f9f4;
    color: #178e77;
    margin: 10px auto;
    display: flex;
    align-items: center;
    padding: 3px 8px;
    border-radius: 2px;
    width: fit-content;
  }

  .text-center {
    text-align: center;
  }

  .blue-highlight {
    background-color: #e5f9f4;
    color: #178e77;
    border-radius: 20px;
    padding: 3px 8px;
  }

  .link-font {
    color: rgba(39, 96, 216, 1);
    text-decoration: underline;
  }
  .link-font:hover {
    cursor: pointer;
  }
  .remark-text-section {
    display: flex;
    gap: 6px;
    min-width: 140px;
    .remark-text {
      max-width: 100px;
      min-width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .table {
    width: 100%;

    .table-header {
      .category {
        min-width: 50px;
      }
      th {
        color: $white;
        background: $primary-gradient-hor;
      }
    }
    tbody {
      td {
        vertical-align: middle;
      }
    }
  }
}
