@import "./variables";

@mixin title-style {
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.07rem;
  color: $titleColor;
  text-transform: capitalize;
}

@mixin d-flex {
  display: flex;
  align-items: center;
}

@mixin d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin d-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin d-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
