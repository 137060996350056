.single-notice-page {
  .pd-decrease {
    padding: 0 5px;
  }
  .cardwrapper-section {
    padding-top: 20px;

    .tendercard-advertisement-shimmer {
      background: #dcdcdc70;
      padding: 10px;
      height: 187px;
      margin: 8px 0;
    }

    .notice-shimmer-section {
      background: #dcdcdc70;
      padding: 10px;
      height: 800px;
      border-radius: 5px;
    }
  }
  .right-side-card-margin {
    margin-top: 0;
  }
}
