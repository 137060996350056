@import "../../Styles/variables";

.reject-bid {
  .info-logo {
    padding: 8px;
    background-color: #dfefff7d;
    width: fit-content;
    border-radius: 50%;
    border: 1px solid #dfefff;
    margin-bottom: 12px;
    .mdi-alert {
      width: 40px;
      height: 40px;
      background-color: #dfefff;
      border-radius: 50%;
      padding: 6px;
      margin-right: 0;
    }
  }
  .alert-section {
    display: flex;
    padding: 8px;
    background: rgba(182, 30, 37, 0.1);
    border-radius: 5px;
    border-radius: 5px;

    .mdi-alert {
      width: 16px;
      height: 16px;
      color: $red;
      margin-right: 9px;
    }

    .withdraw-bid {
      color: $red;
    }
  }

  .popup-btn {
    display: flex;
    justify-content: end;
    margin-top: 16px;

    .btn-cancel {
      padding: 8px 20px;
      background: $white;
      color: $btn-color;
      border: 1px solid $btn-color;
      border-radius: 5px;
      letter-spacing: 0.07em;
      margin-right: 12px;
    }

    .btn-withdraw {
      padding: 8px 20px;
      border-radius: 5px;
      // background: linear-gradient(180deg, #4e83e9 0%, #17cfa4 100%);
      background: $primary-gradient-hor;
      border: 1px solid $btn-color;
      color: $white;
      letter-spacing: 0.07em;
    }
  }

  .text-title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px;
    color: $titleColor;
    text-align: initial;
  }

  .popup-body {
    padding: 16px 0;
    border-top: 1px solid rgb(194, 194, 194);
    border-bottom: 1px solid rgb(194, 194, 194);
  }

  .sub-header {
    font-weight: normal;
    font-size: 14px;
    color: $titleColor;
    text-align: initial;
    margin-bottom: 16px;
  }

  .close-icon {
    float: right;
    cursor: pointer;
    .mdi-close {
      height: 18px;
      width: 18px;
      color: $inputBorder;
    }
  }
  .form-label {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: $fieldColor;
    text-align: left;
  }

  .input-div {
    padding: 1rem 0;
    width: 100%;
    margin: 0px auto;
  }
}
