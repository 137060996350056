@import "../../../Styles/mixin";
@import "../../../Styles/variables";
.subcontract-section {
  .contract-date {
    color: $primaryColor;
    margin-bottom: 10px;
  }

  .subcontract-margin {
    margin-top: 30px;
  }

  .subcontract-card {
    .card-section {
      padding: 16px;
      border-radius: 5px;
      border: none;
      margin-bottom: 10px;

      .subcontract-number-content {
        justify-content: space-between;
        display: flex;
        margin-bottom: 10px;

        .threedot {
          object-fit: contain;
        }
        .subcontract-header {
          display: flex;

          .subcontract-content {
            border-left: 1px solid $darkcontent;
            padding: 0 10px;
          }

          .subcontract-content:nth-child(1) {
            border-left: none;
            padding-left: unset;
          }
          img {
            width: 18px;
            object-fit: contain;
          }

          .contract-tag {
            margin-left: 10px;
            color: #505050;
            font-weight: 600;
            span {
              color: #303030;
              font-weight: 700;
            }
          }
        }
      }

      .subcontract-details {
        .main-title {
          color: $secondaryColor;
          font-weight: 600;
          margin-bottom: 5px;
        }

        .contract-title {
          color: #303030;
        }
      }
    }
  }
}
