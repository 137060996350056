@import "../../Styles/variables";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

.blue-trangle {
    // background-color: #eef7ff;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #eef7ff;
  }
  .mdi-alert {
    width: 16px;
    height: 16px;
    color: $fieldColor;
    margin-right: 9px;
  }
  .blue-box {
    background-color: #eef7ff;
    padding: 4px 12px;
    width: fit-content;
  }
  .form-label {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: $fieldColor;
    text-align: left;
  }