.loader-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    border: 5px solid #ccc;
    border-top-color: #40bc9d;
    box-sizing: border-box;
    background: transparent;
    animation: loading 1s linear infinite;
  }
  .loader-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loader-image img {
    height: 2.5rem;
    width: 2.5rem;
  }
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
