.contactus-section {
  .container {
    .card {
      .alert-warning {
        margin-bottom: 0;
      }

      .contact-content-area {
        .m-2 {
          margin: 5px 0 !important;
        }
        .p-2 {
          font-size: 15px;
        }
        .card-body {
          min-height: 180px;
          justify-content: initial !important;
          min-width: 325px;
          max-width: 325px;
          i {
            font-size: 40px;
          }
          .bi-phone {
            color: #0000003d;
          }

          .bi-envelope {
            color: #b118189e;
          }

          .bi-chat-dots {
            color: blue;
          }
          .contact-title {
            font-size: 15px;
            font-weight: 700;
          }
          .chat-note{
            font-size: 12px;
            margin-top: 10px;
          }
        }
        
      }

      .card-footer {
        i {
          margin-right: 8px;
          font-size: 15px;
        }
        .bi-geo-alt-fill {
          color: #b118189e;
        }

        .bi-facebook {
          color: blue;
        }
      }
    }
  }
}
