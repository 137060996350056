@import "../../Styles/variables";


.uploaded-file-section {
  position: relative;
  display: inline-block;
  &:hover {
    .close-icon {
      display: block;
      transition: 0.2 all ease-in-out;
    }
  }
  .upload-file {
    margin-bottom: 16px;
    padding: 10px;
    margin-top: unset;
    border: 1px solid gray;
    border-radius: 5px;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .document-topic {
      text-decoration: none;
      color: black;
      margin-left: 5px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .close-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    background: $colorBorder;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    display: none;
    transition: 0.2 all ease-in-out;
    line-height: 16px;
    .mdi-trash {
      color: $primaryColor;
      width: 14px;
      height: 14px;
    }
  }
}
