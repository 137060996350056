@import "../../Styles/variables";

.reset-page {
  width: 100%;
 position: relative;
  .reset-popup{
    position: absolute;
    width: 35%;
    position: fixed;
    top: 25%;
    left: 32%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    padding: 30px;
  }
  .form-label {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: $fieldColor;
    text-align: left;
  }
  .popup-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo-size {
    width: 80px;
  }
  .text-area {
    height: 46px;
  }
  .close-icon {
    cursor: pointer;
    position: relative;
    .mdi-eye {
      position: absolute;
      bottom: 14px;
      right: 12px;
      height: 18px;
      width: 18px;
      color: $inputBorder;
    }
  }
  .mg-bottom-20 {
    margin-bottom: 30px;
  }
  .mg-bottom {
    margin-bottom: 20px;
  }
  .padd-btn {
    padding: 5px 12px;
    border-radius: 5px;
    margin-left: 10px;
  }
  .bottom-btn {
    display: flex;
    justify-content: end;
    margin-top: 30px;
  }
  @media (max-width: 768px) {
    .reset-popup{
      width: 50%;
      left: 25%;
    }
  }
  @media (max-width: 480px) {
    .reset-popup{
      width: 90%;
      left: 5%;
    }
  }
}

