@import "../../Styles/variables";

.user-interest {
  .top-header-part {
    background: #f4f4f4;
    .form-section {
      padding: 12px 24px;
    }
    .close-icon {
      .mdi-close {
        width: 24px;
        height: 24px;
      }
    }
  }
}
