.lightbox-portal {
  .btn-handle {
    color: #dc3545;
    position: absolute;
    z-index: 999999;
    height: 60px;
    padding: 2px;
    font-size: 20px;
  }
}
