@import "../../Styles/variables";



.detail-wrapper {
  .mdi-alert {
    width: 16px;
    height: 16px;
    color: $red;
    margin-right: 9px;
  }
  .red-highlight {
    margin: 10px auto;
    display: flex;
    align-items: center;
    background-color: #fbeae8;
    color: #ae2116;
    padding: 3px 8px;
    border-radius: 2px;
    width: fit-content;
  }
  .search-btn {
    padding: 8px 12px;
  }
}
