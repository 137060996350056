@import "../../Styles/variables";

.category-tag {
  padding: 3px 8px;
  background: #d6dae4;
  white-space: nowrap;
  margin-bottom: 8px;
  margin-right: 8px;
  display: inline-block;
  border-radius: 20px;
  color: #1a1a1a;
}
.view-more-btn {
  background: none;
  color: #eee;
  text-decoration: underline;
  &:hover {
    color: #fff;
  }
}

.details-section {
  padding: 24px 16px;
  background: linear-gradient(45deg, #2f80b1, #0053a3);
  color: #fff;
  border-radius: 0;

  .company-details-section {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    height: 100%;
    .logo-section {
      width: 100px;
      height: 100px;
      background-color: #fff;
      padding: 8px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      img {
        width: 100%;
      }
    }
    .company-details {
      .block-title {
        margin-bottom: 0;
      }
      .company-detail-content {
        font-size: 14px;
        line-height: 24px;
      }
      .banner-img {
        height: 28px;
        width: auto;
        margin-left: 8px;
      }
    }
  }
  .bid-details {
    font-size: 20px;
    font-weight: 600;
    color: $titleColor;
  }
  .mg-bottom-24 {
    margin-bottom: 24px;
  }

  .card-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .bid-page-buttons {
    display: flex;
    justify-content: space-between;
    .bid-buutons {
      display: flex;
      gap: 5px;
    }
  }
  .media-icon {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 19px;
      margin-right: 16px;
    }
  }
  .btn-block {
    width: 100%;
    display: flex;
    justify-content: end;

    .bid-document-btn {
      background: transparent;
      color: $white;
      border: 1px solid $white;
      font-size: 14px;
      padding: 8px 16px;
      align-items: center;
      display: flex;
      margin-top: 12px;
      border-radius: 5px;
      .doc-icon {
        width: 16px;
        margin-right: 10px;
      }
    }

    .apply-btn {
      padding: 8px 16px;
      margin-top: 12px;
    }
  }

  .padd-16 {
    padding: 16px;
  }

  .show-bid {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.07em;
  }
  .block-title {
    margin-bottom: 12px;
    font-size: 20px;
  }
  .detail-content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.07em;
    margin-right: 8px;
    min-width: auto;
    margin-bottom: 8px;
    color: #fff;
  }

  .content-right {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.07em;
    padding-bottom: 8px;
    color: #fff;
  }

  .terms-condition-info {
    background: $lightPink;
    color: $yellow;
    padding: 8px;
    width: 35%;

    span {
      vertical-align: middle;
      padding-left: 2px;
    }
  }

  .mdi-information {
    width: 20px;
    color: #c88f95;
  }
}
