@import "../../Styles/variables";
@import "../../Styles/mixin";

.interest {
  .interest-content {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border: 1px solid #b1b0b0;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
padding: 30px;
  }
  .interest-block {
    @include d-start;
    align-items: flex-start;
    .content-block {
      margin-bottom: 20px;
      width: 75%;
      &:nth-child(1) {
        width: 25%;
        color: $greyColor;
        .content {
          font-weight: 400;
        }
      }

      ul {
        padding: 0 0 0 12px;

        li {
          width: 40%;
        }
      }
      .multi {
        @include d-flex;
        flex-wrap: wrap;
      }
    }
    .content {
      color: $titleColor;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.07em;
      line-height: 24px;
    }
  }
}
