@import "../../Styles/variables";

.loader {
  display: flex;
  justify-content: center;
}

.login-page {
  overflow: hidden;
  .box-field {
    margin-top: 6px;
  }
  .popup-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo-size {
    width: 133px;
  }
  .card {
    padding: 10px;
    border-radius: 0px 5px 5px 0px;
    border: transparent;
  }

  .material-textfield {
    margin-bottom: 15px;
    position: relative;
  }

  .text-area {
    width: 100%;
  }

  .text-field {
    position: absolute;
    font-size: 1rem;
    left: 0;
    top: 20px;
    transform: translateY(-50%);
    background-color: white;
    color: $inputBorder;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
  }

  input {
    font-size: 1rem;
    outline: none;
    border: 0.5px solid $contentColor;
    border-radius: 5px;
    padding: 0.5rem 0.7rem;
    color: $titleColor;
    transition: 0.1s ease-out;
  }

  input:focus {
    border-color: $primaryColor;
  }

  input:focus + .text-field {
    color: $primaryColor;
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }

  input:not(:placeholder-shown) + .text-field {
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }

  .eyeoff-outline {
    cursor: pointer;
    width: 18px;
    height: 15px;
    position: absolute;
    right: 15px;
    top: 9px;
    color: $contentColor;
  }

  .custom-checkbox {
    display: flex;
  }
  .text-center {
    text-align: center;
  }
  .checkbox-content {
    display: flex;
    margin-bottom: 18px;
    justify-content: space-between;
    text-align: center;
    align-items: center;
  }

  .small-content {
    color: $primaryColor;
    font-weight: 400;
    cursor: pointer;
    margin-left: 24px;
  }

  .custom-content {
    margin-left: 10px;
    color: $titleColor;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
  }

  .submit-btn {
    background: $primary-gradient-hor;
    border-radius: 5px;
    color: $white;
    border: 1px solid;
    padding: 8px;
    margin-bottom: 20px;
    width: 100%;
  }

  .large-content {
    text-align: center;
    font-size: 22px;
    margin-bottom: 40px;
    color: $primaryColor;
  }

  .title-header {
    text-align: center;
  }

  .box-field input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid $contentColor;
    position: absolute;
    left: 25px;
    height: 20px;
    width: 20px;
    background-color: $white;
  }

  /* On mouse-over, add a grey background color */
  .box-field:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .box-field input:checked ~ .checkmark {
    border: transparent;
    border-radius: 5px;
    background-color: $primaryColor;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .box-field input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .box-field .checkmark:after {
    border: transparent;
    left: 7px;
    top: 2px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .box-field {
    padding-left: 30px;
  }

  .checkbox-header {
    display: flex;
  }

  .re-captcha {
    margin-bottom: 40px;
  }
  .close-icon {
    cursor: pointer;
    .mdi-close {
      height: 18px;
      width: 18px;
      color: $inputBorder;
    }
  }
}
