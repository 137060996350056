@import "../../../Styles/variables";
@import "../../../Styles/mixin";
.contract-form {
  .card-section {
    border: none;
    padding: 20px;
    margin-top: 30px;

    .maintitle {
      margin-bottom: 40px;
    }

    .form-section {
      .input-field {
        margin-bottom: 16px;
      }

      input[type="number"] {
        height: unset;
        border: unset;
      }

      .error {
        margin-top: 5px;
      }
      .MuiTextField-root {
        width: 100%;
      }
      .MuiOutlinedInput-input {
        padding: 8px;
      }
      .MuiFormLabel-root {
        color: $contentColor;
        font-size: 14px;
        top: -6px;
      }
      .MuiInputLabel-root.Mui-focused {
        color: $primaryColor;
      }
      .MuiFormLabel-root.Mui-focused {
        top: 0;
        color: $primaryColor;
      }
      .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
          border-color: $primaryColor;
        }
      }
      .submit-btn {
        width: 100%;
      }
      .note-text {
        margin-top: 16px;
        font-weight: 400;
        color: $greyLight;
        font-size: 14px;
      }
    }
  }
}
