@import "../../Styles/variables";
@import "../../Styles/mixin";

.check-button {
  @include d-flex;
  // padding: 10px 20px;
  // background: #ffffff;
  // border: 1px solid rgba(113, 113, 113, 0.5);
  // border-radius: 5px;
  // font-weight: 600;

  background-color: #f4f4f4;
  padding: 0;
  font-weight: 600;
  margin-bottom: 10px;

  img {
    height: 24px;
    margin-right: 10px;
  }
  span {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.07em;
    color: $inputColor;
    text-align: initial;
  }
  &.checked {
    // border-color: $darkRed;
    // background: $lightPink;
    background-color: #f4f4f4;
  }
}

.checkbutton-section {
  input {
    margin-right: 10px;
    height: 18px;
    width: 18px;
  }

  .main-heading {
    font-weight: 600;
    color: #574f4f;
  }
}

.check-button-group {
  .check-button-list {
    @include d-flex;
    flex-wrap: wrap;
    .check-button {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .select-all-btn {
    background: $outerlineColor;
    color: $colorblack;
    border: none;
  }
}
