@import "../../Styles/variables";

.edit-personal-details {
  .ReactModal__Overlay {
    background-color: $primaryColor;
  }
}
.my-profile-section {
  background-color: $imgBackground;
  padding-top: 24px;
  padding-bottom: 60px;
  .d-flex {
    text-align: initial;
    align-content: center;
    align-items: center;
  }
  .no-file {
    color: red;
  }
  .mg-left-25 {
    margin-left: 25px;
  }
  .edit-org-details {
    font-size: 14px;
  }
  .certificate-content {
    margin-left: 30px;
  }
  .border {
    border: 2px solid #ececec !important;
    border-radius: 5px;
  }
  .detail-content {
    .Personal-details-topic {
      font-size: 17px;
      margin-bottom: 20px;
      display: flex;
      padding: 0px 8px;
    }
  }
  .editorg-icon {
    display: flex;
  }
  .topic {
    font-size: 700;
  }
  text-align: left;
  .profile-image {
    width: 86px;
  }
  .profile-details {
    right: 20px;
  }
  .organization-detail-edit-button {
    position: absolute;
    top: 0px;
    right: 20px;
  }

  .profile-info{
    border: 1px solid #b1b0b0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    padding: 30px;
  }
  .person-details {
    margin-left: 24px;

    .person-name {
      display: flex;
    }
    .person-email {
      margin: 8px 0px;
    }
  }
  .big-circle {
    background: $primaryColor;
    width: 85px;
    border-radius: 50%;
    height: 85px;
    text-align: center;
    margin-right: 5px;
  }
  .circle-font {
    margin: 0 auto;
    font-size: 55px;
    color: white;
    font-weight: 600;
    padding: 3px;
  }
  .change-password {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .btn-name {
    border-radius: 5px;
    color: $red;
    font-size: 16px;
  }
  .btn-name {
    padding: 8px 20px;
  }
  .btn-edit {
    padding: 8px 20px;
  }
  .btn-edit-icon {
    border-width: 1px;
    background-color: white;
    padding: 4px 18px 4px 7px;
    color: $secondaryColor;
    border: 1px solid $secondaryColor;
    position: relative;
  }
  .icon-size {
    width: 20px;
    margin-right: 5px;
  }
  .my-profile-section .change-password button {
    height: 15%;
    width: 20%;
    display: flex;
  }

  .individual-details {
    margin-top: 15px;
    margin-bottom: 24px;
  }

  .topic {
    font-weight: bold;
    margin-top: 0px;

    .individual-details {
      margin-top: 24px;
    }
  }

  .person-details {
    .person-name {
      font-weight: bold;
    }
  }

  .table-org {
    margin-top: 0px;
  }
  .lastdata {
    margin-top: 24px;
  }
  .head-line {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .table td,
  .table th {
    border-top: none;
  }
  .width-25 {
    width: 25%;
    padding-bottom: 16px;
  }
  .width-45 {
    width: 45%;
    padding-bottom: 16px;
  }
  .card {
    padding: 24px;
    box-shadow: 0px 10px 20px 0px #0000000a;
    width: 90%;
    margin: 0 auto;
  }
  td {
    padding: 0;
  }
  .profile-content {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 25px;
  }

  .app-letter {
    white-space: nowrap;
  }
  .download-app-letter {
    position: absolute;
    color: $secondaryColor;
    display: flex;
    white-space: nowrap;
  }
  .profile-details-Pic {
    margin-top: 24px;
  }
  .tab-block {
    margin-top: 32px;
    margin-bottom: 0;

    .tab{
      margin-right: 4px;
    }
  }
}
@media screen and (max-width: 992px) {
  .my-profile-section {
    .card {
      padding: 16px;
    }
    .profile-content {
      margin-bottom: 15px;
    }
    .profile-image {
      .img-profile {
        width: 65%;
      }
    }
    .icon-size {
      width: 18px;
    }
    .btn-edit-icon {
      padding: 4px 8px;
    }
    .icon-size {
      top: 3px;
    }
    .download {
      left: 46%;
      top: 74.5%;
    }
  }
}
@media screen and (max-width: 768px) {
  .my-profile-section {
    .download-icon-app {
      display: none;
    }
    .card {
      padding: 14px;
    }
    .profile-content {
      margin-bottom: 10px;
    }
    .profile-image {
      .img-profile {
        width: 60%;
      }
    }
    .icon-size {
      width: 16px;
    }
    .btn-edit-icon {
      padding: 4px 8px;
      font-size: 14px;
    }
    .icon-size {
      top: 3px;
    }
    .width-25 {
      font-size: 14px;
    }
    .width-45 {
      font-size: 14px;
      width: 25%;
    }
    .download {
      left: 46%;
      top: 74.5%;
    }
  }
}
