@import "../../Styles/variables";

.bidder-list {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #303030;
  width: 100%;
}

.select-box {
  padding: 7px 15px;
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-left: 10px;
}

.select-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: #616161;
  text-align: left;
}

.mg-bottom-2 {
  margin-bottom: 20px;
}

.mg-0 {
  margin-bottom: 0;
}

.detail-wrapper {
  padding: 0px 0px 40px 0px;
  .opening-date {
    width: 200px;
  }

  .blue-box {
    border-left: 2px solid $secondaryColor;
    margin: 0 16px;

    .mdi-alert {
      color: $secondaryColor;
    }
    .form-label {
      color: $secondaryColor;
    }
  }

  .btn {
    padding: 6px 12px;
    font-size: 14px;
  }
}

.mdi-print {
  width: 16px;
  height: 16px;
  color: $white;
  margin-right: 9px;
}

.open-bids {
  width: 15%;
  background: #ff0026;
  padding: 8px 12px;
  border: 1px solid transparent;
  color: #fff !important;
  border-radius: 5px;
  margin: 0px 12px 0px 12px;
}

.cancel-extend {
  padding: 8px 20px;
  background: #fff;
  color: #4e83e9;
  border: 1px solid #4e83e9;
  border-radius: 5px;
  letter-spacing: 0.07em;
  margin-right: 12px;
}

.extend-date {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.detail-wrapper {
  .info-flex {
    padding-top: 12px;
  }
}

.view-switch-section {
  .view-card {
    background: #ffffff;
    border: 0.5px solid #919191;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 2px;
    width: fit-content;

    .imagelist {
      border: 2px solid transparent;
      padding: 4px 10px;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      white-space: nowrap;
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .view-icon {
      font-size: 28px;
    }
    
    .list-icon {
      padding: 2px;
      border-radius: 4px;
    }

    .hide-active {
      border: 2px solid #ff0026;
      color: #ff0026;
      .view-icon {
        color: #ff0026;
      }
    }

    .show-active {
      border: 2px solid #28a745;
      color: #28a745;
      .view-icon {
        color: #28a745;
      }
    }
  }
}
