.company-content {
  padding: 40px;
  .row {
    display: block;
    line-height: 3;
    .mar {
      margin: 0 auto;
      width: 900px;
      text-align: justify;
    }
  }
  ol {
    margin: 10px 0 10px 0;
    list-style: none;
    text-align: center;
    li {
      strong {
        font-size: 24px;
      }
    }
  }
  p {
    strong {
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 768px) {
  .mar {
    width: auto !important;
  }
}
