@import "../../../Styles/variables";
@import "../../../Styles/mixin";

.notice-details-section {
  // margin-top: 8px;
  .card-section {
    border: none;
    padding: 20px;
    border-radius: 5px;

    .reward-section {
      .first-content {
        font-weight: 800;
      }
      .second-content {
        font-weight: 600;
      }
    }
    img {
      width: 100%;
      margin-bottom: 20px;
    }

    .notice-content {
      display: flex;
      margin-bottom: 10px;

      .first-content {
        width: 155px;
        color: $black;
        font-weight: 800;
        line-height: 19px;
      }

      .second-content {
        width: calc(100% - 155px);
        color: $black;
        font-weight: 600;
        line-height: 19px;
      }
    }
  }
}
