.notice-section {
  // margin-top: 10px;
  .img-block {
    width: 100%;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    img {
      height: auto;
      width: 100%;
      border-radius: 5px;
    }
  }
  .btn-option {
    margin-top: 16px;

    text-align: center;
    img {
      margin-right: 10px;
    }
    .btn-margin {
      margin-left: 10px;
    }
  }
}

@media print {
  .img-block {
    width: 100% !important;
    height: 100% !important;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
