@import "../../Styles/variables";


.success-box {
  display: flex;
  background-color: $sucess-bg;
  color: $secondaryColor;
  padding: 5px 10px;
  font-size: 14px;
  margin-bottom: 16px;
}
.confirmation-popup {
  .icon-block {
    padding: 5px 10px;
  }
  .border-none {
    border: none;
  }
  .text-title{
    font-size: 20px;
  }
  .base-btn {
    display: flex;
    float: right;
  }
  .img-wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 25px;
  }
  
  .image-box{
      border: 1px solid gray;
      padding: 5px 10px;
  }
  .mdi-alert {
    width: 16px;
    height: 16px;
    color: $secondaryColor;
    margin-right: 9px;
  }
  .btn-padd {
    padding: 7px 12px;
    border-radius: 3px;
  }
}
