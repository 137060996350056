@import "../../Styles/mixin";
@import "../../Styles/variables";

.boq-img-popup {
  .close-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .boq-topic {
      margin: 0px 0px 5px 0px;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
    }
    .mdi-close {
      height: 20px;
      width: 20px;
      color: #535050;
    }
  }
  .notice-img {
    width: 100%;
    height: auto;
  }

  .image-area {
    width: 100%;
    height: 400px;
    object-fit: contain;
  }
  .popup-scroll-section {
    // overflow-y: scroll;
    // max-height: calc(100vh - 275px);
  }
  .btn-option {
    margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
