@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
@import "../../Styles/variables";

.drop-zone-page {
  margin-bottom: 16px;
  .font-sample {
    font-size: 12px;
  }
  .icon-size {
    width: 1rem;
    align-items: center;
    float: right;
    margin: 0 6px 6px;
  }
  .icon-size:hover {
    color: darkgray;
  }
  .float {
    a {
      justify-content: end;
      display: flex;
    }
  }
  .pdf-content {
    width: fit-content;
    padding: 10px;
    margin-top: unset;
    border: 1px solid gray;
    border-radius: 5px;
  }
  .text-wrapper {
    margin-left: 15px;
  }
  .block-title {
    list-style-type: none;
  }
  .upload-venture {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    color: #363940;
    text-align: left;
  }
  .poped-box {
    border-radius: 5px;
    border: 1px dashed $secondaryColor !important;
    padding: 10px 20px;
  }
  .small-text {
    padding: 4px 0px;
    font-family: Nunito Sans;
    font-size: 15px;
    font-weight: 400;
    color: #8098a5;
    line-height: 29px;

    text-align: left;
  }
  .file-name {
    padding: 20px !important;
    border-top: 1px solid #8098a5;
  }
  .img-width {
    width: 40px;
    height: 40px;
  }
  .small-text {
    justify-content: space-between;
    display: flex;
    svg {
      width: 20px;
    }
  }
  .document-topic {
    font-weight: 600;
    font-size: 15px;
  }
  .cross {
    margin-left: 20px;
    cursor: pointer;
  }
  .img-text-wrapper {
    display: flex;
  }
  .mb {
    color: #8098a5;
    font-weight: 500;
    font-size: 14px;
  }
}
