@import "../../Styles/variables";
@import "../../Styles/mixin";

.saved-bids {
  .container {
    .cardwrapper-section {
      margin-top: 40px;
    }
    .saved-bids-card {
      margin-top: 52px;
    }
  }

  .advertisement-margin {
    margin-top: 125px;
  }

  .right-side-card-margin{
    margin-top: 128px !important;
  }
}
