@import "../../Styles/variables";

.bid-wrapper {
  position: relative;
  margin-top: 78px;
  padding-bottom: 66px;

  .bid-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .register-bid {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: $white;
    text-align: left;
  }

  .bid-img {
    padding: 42px 62px 42px 68px;

    border-radius: 16px;
    background-image: url("../../images/bid-upp.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bid-register {
    text-align: center;
  }
  .register-btn {
    margin-top: 19px;
    margin-bottom: 19px;
    font-size: 20px;
    background: $white;
    color: $btn-color;
    border: 1px solid transparent;
    padding: 6px 20px;
    border-radius: 5px;
  }

  .bid-category {
    img {
      width: 200px;
    }
  }

  .bid-img {
    img {
      height: 23px;
    }
  }
  @media (max-width: 992px) {
    .bid-wrapper {
      margin-top: 24px;
    }
    .register-bid {
      font-size: 20px;
    }
    .register-btn {
      padding: 3px 10px;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .client-header {
      margin-bottom: unset !important;
    }
    .btn-register {
      text-align: center;
    }
  }
  @media (max-width: 480px) {
    .bid-img {
      padding:  15px 20px 15px 20px;
    }

    .register-bid {
      font-size: 16px;
    }
    .register-btn {
      white-space: nowrap;
    }
  }
}
