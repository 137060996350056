@import "../../Styles/variables";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

.error-margin {
  margin-right: 32px;
}

.catalog-label {
  font-weight: inherit;
}

.width-100 {
  width: 100%;
}
.new-tender {
  .radio-box input[type="radio"]::after {
    display: none;
  }

  .bidbond-section {
    display: flex;
  }
  .radio-box input[type="radio"]:checked::after {
    display: none;
  }
  .catalog-label {
    line-height: 0px !important;
  }
  .check-section {
    display: flex;
    align-items: baseline;
  }
  .check-box {
    margin-right: 45px;
    justify-content: center;
    display: flex;
  }
  .radio-box {
    display: flex;
  }
  .outer-box {
    .radio-box {
      position: relative;
      min-width: 200px;
      label {
        margin-left: 0;
      }
      input[type="radio"]:after {
        top: 0px;
        left: 0px;
      }

      input[type="radio"]:checked:after {
        top: 0px;
        left: 0px;
      }
    }
  }
  .form-section {
    display: flex;
    width: 100%;
  }
  .form-check-label {
    padding-left: 23px;
    vertical-align: middle;
  }
  .blue-trangle {
    // background-color: #eef7ff;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #eef7ff;
  }
  .mdi-alert {
    width: 16px;
    height: 16px;
    color: $fieldColor;
    margin-right: 9px;
  }
  .blue-box {
    background-color: #eef7ff;
    padding: 4px 12px;
    border-radius: 4px;
  }
  .form-control {
    height: unset;
    padding: 6px 15px;
  }
  // .form-check {
  //   padding-right: 50px;
  //   padding-left: unset;
  // }
  .line {
    border-bottom: 1px solid #d5d5d5;
    width: 100%;
    margin: 24px 1px;
  }
  .bigbond-checkbox {
    display: flex;
    white-space: nowrap;
    margin-bottom: 20px;
  }

  .mg-left {
    margin-left: 5px !important;
  }

  .mg-left-20 {
    margin-left: 20px;
  }
  .same-as-previous {
    margin-left: 12px;
  }
  label {
    margin-bottom: 0 !important;
  }
  .bi-calendar3 {
    position: absolute;
    top: 2px;
    right: 0;
  }
  .bi.bi-calendar3 {
    position: absolute;
    top: 31px;
  }
  .bi-clock {
    position: absolute;
    top: 40px;
    right: 27px;
  }

  .col-md-6 {
    margin-bottom: 24px;
  }
  .d-flex {
    display: flex;
  }
  .right {
    text-align: right;
  }

  .mb-10 {
    margin-bottom: 10px !important;
  }
  .head-titile {
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    text-align: left;
  }
  .outer-box {
    padding: 20px;
    border: 1px solid rgb(177, 176, 176);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    .radio-box {
      position: relative;
      margin-bottom: 0;
      padding: 0;
    }
  }
  .base-content {
    padding: 0 16px;
  }
  .primary-btn {
    background: $primary-gradient !important;
    border: 1px solid #0065c3;
    // width: 141px;
    color: #ffffff;
    border-radius: 5px;
    padding: 8px, 20px, 8px, 20px;
  }
  .form-label {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: $fieldColor;
    text-align: left;
    .red {
      color: rgb(228, 41, 41);
      font-size: 14px;
    }
    .high-light {
      color: $secondaryColor;
      font-size: 14px;
      font-weight: 700;
    }
  }
  .note {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  .sub-content {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    margin-bottom: 12px;
  }
  .white-btn {
    background: #f8f8f8;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-left: 3px;
    text-align: left;
    padding: 8px, 20px, 8px, 20px;
  }
  .require-document {
    .blue-box {
      width: fit-content;
      .form-label {
        font-size: 12px;
      }
    }
  }
}
.rs-picker-toggle-wrapper {
  width: 100% !important;
  display: block;
  position: relative;
  z-index: 0;
  .rs-picker-toggle {
    border-radius: 4px;
    .rs-picker-toggle-value {
      color: #333;
    }
  }
  .rs-picker-toggle.rs-btn {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
@media (max-width: 768px) {
  .new-tender {
    .right {
      .primary-btn {
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 480px) {
  .new-tender {
    .outer-box {
      padding: 10px !important;
    }
    .col-md-6 {
      margin-bottom: 10px !important;
    }
    .form-control {
      padding: 7px 10px !important;
    }
  }
}
.form-check-label {
  white-space: nowrap;
}
