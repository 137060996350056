@import "../../Styles/variables";

.organization-details-popup {
  margin-bottom: 20px;

  .popup-nav {
    display: flex;
    justify-content: space-between;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #000000d6;
    margin-bottom: 10px;
  }

  .register-btn {
    float: right;
  }

  .register-btn:hover {
    color: #fff;
  }
}
.topheader-section {
  display: flex;
  align-items: center;

  .dropdown {
    margin-right: 10px;
    cursor: pointer;
  }

  .btn-dropdown {
    .btn-secondary {
      border: 1px solid transparent;
      color: $white !important;
      background: $primary-gradient;
      border-radius: 5px;
      margin: 0px 12px 0px 12px;

      &:focus {
        box-shadow: none;
      }
    }

    .dropdown-toggle::after {
      position: unset;
      color: white;
      margin-left: 5px;
    }

    .dropdown-menu {
      padding: 0;
      min-width: 220px;

      .menu-title {
        padding: 6px 15px;
        font-size: 15px;
        font-weight: 500;
        border-bottom: 1px solid $borderColor;
        p {
          font-size: 10px;
          font-weight: 500;
          color: $fieldColor;
        }
        &:hover {
          background: $primary-gradient;
          color: $white;

          p {
            color: $white;
          }
        }

        :last-child {
          border: none;
        }
      }
    }
  }

  .profile {
    font-size: 12px;
    text-align: left;
    margin-left: -4px;
    margin-top: 2px;
  }
  .search-btn {
    white-space: nowrap;
  }
  .pd-btn {
    margin: 0 0 0 20px;
  }
  .mdi-close {
    width: 20px;
    height: 20px;
    color: grey;
  }
  .user-name {
    padding: 5px;
    margin: 0;
    font-size: 16px;
    line-height: 14px;
    font-weight: 700;
    color: white;
  }

  .membership-details {
    font-size: 12px;
    font-weight: 600;
    color: white;
    line-height: 22px;

    span {
      font-weight: 800;
    }
  }
  .profile-img {
    position: relative;
  }
  .icon-text-section {
    margin-left: 5px;
  }
  .user-profile-area {
    flex-direction: column;
  }
  .dropdown-item {
    display: flex;
    cursor: pointer;
    padding: 0;

    .user-img-area {
      padding: 10px;
      background: $primary-gradient;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .user-details-area {
      padding: 10px;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 15px,
        rgba(0, 0, 0, 0.05) 0px 5px 05px;

      .dropdown-item {
        padding: 8px;
        border: 1px solid $secondaryColor;
        color: $secondaryColor;

        .mdi-close {
          color: $secondaryColor;
        }
      }

      .dropdown-item:hover {
        background-color: #e9ecef12;
      }
      .dropdown-item.active,
      .dropdown-item:active {
        color: #16181b;
        text-decoration: none;
        background-color: #e9ecef2b;
      }

      .logout-btn:hover {
        background-color: #e6e9ec0a;
      }
      .logout-btn {
        margin-left: 8px;
        border: 1px solid $primaryColor;

        .logout-section {
          color: $primaryColor;

          .mdi-close {
            color: $primaryColor;
          }
        }
      }
    }
  }
  .circle {
    background: #fff;
    width: 90px;
    border-radius: 50%;
    height: 90px;
    text-align: center;
    margin-bottom: 5px;
  }
  .circle-font {
    font-size: 55px;
    color: $primaryColor;
    font-weight: 600;
    padding: 3px;
    margin: 0 auto;
  }
  .topheader-content {
    display: flex;
    justify-content: space-between;

    .content {
      color: $primaryColor;
      margin: 0;

      .sub-content {
        color: $titleColor;
        text-transform: lowercase;
      }
    }

    .small-content {
      color: $secondaryColor;
      margin: 0;

      .login-toggle {
        cursor: pointer;
      }
    }

    a {
      text-decoration: none !important;
    }

    .register {
      color: $primaryColor;
    }
  }

  .orange-btn {
    background: #ffffff;
    color: #4e83e9;
    border: 1px solid #4e83e9;
    padding: 8px 28px;
    border-radius: 5px;
  }
}
.dropdown-profile-edit {
  position: absolute;
  left: -3px;
  padding: 0;
  color: red;
  margin-left: -225px;
}
.dropdown-toggle::after {
  position: absolute;
  top: 16px;
  right: -11px;
}
.dropdown-menu {
  min-width: 270px;
  margin-top: 13px;
}

@media (max-width: 992px) {
  .topheader-section {
    .profile {
      margin-top: 1px;
    }
  }
}
@media (max-width: 768px) {
  .topheader-content {
    .content {
      font-size: 12px;
    }
    .small-content {
      font-size: 14px;
      white-space: nowrap;
      padding: 10px;
    }
  }
  .orange-btn {
    margin-right: 15px;
  }
  .form-inline {
    display: none;
  }
}
.pd-btn {
  margin-left: 20px;
  background-color: $white;
  border-radius: 5px !important;
  padding: 8px 20px;
}
@media (max-width: 480px) {
  .user-name {
    font-size: 14px;
  }

  .topheader-section {
    margin-top: unset;
    position: unset;
    .pd-btn {
      padding: 5px 12px !important;
    }
    // height: 95px;
    .search-btn {
      height: unset !important;
    }
    .hide-text {
      .small-content {
        display: none;
      }
    }
  }
  .topheader-content {
    .content {
      font-size: 12px;
    }

    .small-content {
      white-space: break-spaces;
      font-size: 13px;
      .login-toggle {
        white-space: nowrap;
      }
    }
  }
  .orange-btn {
    padding: 3px 12px !important;
  }
  .register {
    font-size: 12px;
  }
}
@media (max-width: 376px) {
  .topheader-section {
    .hide-text {
      .small-content {
        display: none;
      }
    }

    .filter-search .card {
      width: 265px;
    }
    .result-wrapper .tender-title {
      font-size: 10px;
      white-space: normal;
      .sub-title {
        font-size: 12px;
      }
    }
  }
}
