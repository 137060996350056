@import "../../Styles/variables";

.table-rows {
  width: 100%;
  textarea {
    resize: none;
  }
  .area-1 {
    width: 9.9% !important;
  }
  .area-2 {
    width: 19.9%;
  }
  .area-4 {
    width: 29.6% !important;
  }
  .area-last {
    width: 19.5%;
  }
  .outline {
    border: 1px solid $colorBorder;
  }
  .mg-btn {
    margin: 7px 0;
  }
  input[type="text"] {
    -moz-appearance: textfield;
    width: 100%;
    border: 1px solid #cecece;
    padding-left: 10px;
  }
  .btn-show {
    color: $secondaryColor;
    border: 1px solid $secondaryColor;
    background-color: $white;
    padding: 3px 10px;
    margin: 2px;
    border-radius: 3px;
  }
  .p-1 {
    text-align: center;
  }
  .amt {
    width: 82% !important;
  }
  .float-right {
    float: right;
    justify-content: end;
  }

  .width-100 {
    width: 100%;
  }
  .x-btn:hover {
    color: red;
  }
  .x-btn {
    border: none;
    background-color: transparent;
    margin-right: 15px;
  }
  .red {
    color: $secondaryColor;
    font-weight: 700;
    font-size: 20px;
  }
  .sn-box {
    border: 1px solid gray;
    border-radius: 5px;
    margin: 4px;
    background-color: #ffff;
  }
  .sm-area {
    width: 100%;
  }
  .lg-area {
    width: 100%;
  }
  .mid-area {
    width: 100%;
  }
  .checkbox {
    margin-right: 3px;
  }

  .checkbox-blank {
    margin: 5px;
  }
}
