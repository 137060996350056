@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
@import "../../Styles/variables";
.register-form {
  margin-top: 50px;
  .btn-gap {
    margin-left: 3px;
  }

  li {
    list-style-type: none;
  }
  
  .primary-btn {
    width: unset !important;
  }

  .form-section {
    display: flex;
    width: 100%;
    margin: 4px 2px;
  }
  .line {
    border-bottom: 1px solid #d5d5d5;
    width: 100%;
    margin: 24px 1px;
  }
  .small-content {
    color: $white;
    font-weight: 600;
  }

  .mb-3 {
    width: 90%;
    margin: 0 auto;
  }

  .btn-space {
    margin-right: 20px;
  }
  .img-content {
    display: flex;
    margin-bottom: 25px;
  }

  .line {
    border-bottom: 1px solid #d5d5d5;
    width: 100%;
    margin: 24px 1px;
  }

  .bi-calendar3 {
    position: absolute;
    top: 40px;
    right: 27px;
  }
  .bi-clock {
    position: absolute;
    top: 40px;
    right: 27px;
  }

  .red {
    color: rgb(228, 41, 41);
  }
  .outer-box {
    padding: 20px;
  }
  .d-flex {
    display: flex;
  }
  .right {
    text-align: right;
  }
  .maintitle {
    color: $white;
    font-weight: bold;
    .small-content {
      color: $white;
      font-weight: 600;
    }
  }
  .head-titile {
    font-family: Nunito Sans;
    font-size: 26px;
    font-weight: 600;
    line-height: 35px;
    color: $secondaryColor;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 40px;
    margin-bottom: 24px;
  }
  .line-section {
    text-align: end;
    margin-bottom: 10px;
  }
  .outer-box {
    border: 1px solid $colorBorder;
  }

  .left-box {
    border: 1px solid rgb(177, 176, 176);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 5px 0px 0px 5px;
    width: 100%;
    height: 100%;
    // background: linear-gradient(180deg, #4e83e9 0%, #17cfa4 100%);
    background: $primary-gradient-hor;
  }
  .right-box {
    background-color: $white;
    border: 1px solid rgb(177, 176, 176);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 0px 5px 5px 0px;
    width: 100%;
    height: 100%;
  }

  .primary-btn {
    background: $primary-gradient !important;

    width: 141px;
    color: #ffffff;
    border-radius: 5px;
    padding: 8px, 20px, 8px, 20px;
  }
  .form-label {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: $fieldColor;
    text-align: left;
  }
  .note {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  .sub-content {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    margin-bottom: 12px;
  }

  .white-btn {
    background: #f8f8f8;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;

    text-align: left;
    border-radius: unset !important;
    padding: 8px, 20px, 8px, 20px;
  }
}
@media screen and (max-width: 992px) {
  .register-form {
    .img-logo {
      margin-left: 36px;
    }
    .maintitle {
      margin: 50px 36px 28px;
    }
  }
}
