@import "../../Styles/variables";

.detail-wrapper {
  background-color: $imgBackground;
  padding: 24px 0px 40px 0px;

  .borderline-btn {
    border-bottom: 1px solid $borderLine;
  }
  .expire-bid {
    text-align: center;
    padding: 4rem;
  }

  .imageCenter {
    text-align: center;
    padding: 0.5rem;
  }
  .error {
    display: flex;
    justify-content: center;
  }

  .expiry-img {
    width: 15%;
  }
}
