@import "../../Styles/variables";

.document-wrapper-block {
  border: 1px solid #e0e0e0;
  padding: 0;
  .wrapper-block {
    margin: 16px 16px 0 16px;

    .catalogue-files {
      margin-top: 24px;
      .catalogue-sub-title {
        font-size: 16px;
        font-weight: 700;
        color: black;
        padding-bottom: 10px;
      }
    }
    .upload-file {
      margin-top: 4px;
    }
  }
  .botton {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    button {
      padding: 8px 16px;
    }
  }
  .error {
    justify-content: initial;
  }
  .border-line {
    border-top: 1px solid $borderColor;
    margin: 12px 0px;
    height: 0;
  }
}

.boq-bill {
  border: 1px solid $colorBorder;
  padding: 16px;
}
.poped-box {
  padding: 10px 20px;
}
.small-text {
  padding: 4px 0px !important;
}
.venture-field {
  float: left;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.03em;
  color: $yellow;
}

.file-uploadimg {
  img {
    width: 33px;
    height: 33px;
    margin-bottom: 19px;
  }
}

.venture-img {
  text-align: center !important;
}
.save-section {
  gap: 10px;
  display: flex;
}

.upload-venture {
  font-weight: 600;
  font-size: 16px;
  color: $uploadFile;
  text-align: left;
}

.drop-border {
  border: 1px dotted $inputBorder;
  padding: 30px;
  background: $imgBackground;
  border-radius: 8px;
}

.drop-zone {
  .inline-block {
    display: inline;
  }

  .drop-icon {
    border: 1px solid red !important;

    img {
      width: 30px;
    }

    .dzu-dropzone {
      text-align: center;
      // border: 1px solid $primaryColor !important;
      background-color: $red;
      margin-bottom: 20px;
      overflow: hidden;
      color: $contentColor;
      font-weight: 500;
      font-size: 14px;
      padding-bottom: 24px;

      .dzu-icon {
        padding: 32px 0 18px 0;
      }

      span {
        text-decoration: underline;
        color: $borderColor;
      }
    }
  }
}

.drag-file {
  color: $greyZone;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0.25px;
}
