@import "../../Styles/variables";
.edit-details {
  padding: 0px 7px 7px;
  .top-head {
    align-items: center;
    margin-bottom: 20px;
    position: relative;
  }
  .mdi-close {
    position: absolute;
    top: -4px;
    right: -4px;
  }
  .upload-venture {
    font-weight: 700;
    margin-top: 12px;
  }
  .update-topic {
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
    color: $secondaryColor;
    font-size: 26px;
  }
  .company {
    font-size: 1rem;
    outline: none;
    border: 0.5px solid #919191;
    border-radius: 5px;
    padding: 0.5rem 0.7rem;
    color: #303030;
    transition: 0.1s ease-out;
    margin-left: 10px;
  }
  .red-btn {
    border: 1px solid #2760d8;
    color: #2760d8;
    border-radius: 3px;
  }

  .st-line {
    margin-bottom: 24px;
    display: flex;
    width: 100%;
    .col-md-4 {
      width: 110%;
    }
  }
  .line-section {
    display: flex;
    justify-content: center;
  }
  .primary-btn {
    background: $primary-gradient !important;
    color: white;
  }
  .btn-cancel {
    padding: 8px 20px;
    background: $white;
    color: $secondaryColor;
    border: 1px solid $btn-color;
    border-radius: 5px;
    letter-spacing: 0.07em;
  }
  .form-control {
    margin-bottom: 7px;
  }

  .custom-dropzone {
    .drop-zone-page {
      .poped-box {
        width: 135px;
        height: 135px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
      }
    }
  }
  .about_company_textarea {
    resize: none;
  }
  .image-section {
    position: relative;

    img {
      width: 135px;
      height: 135px;
      object-fit: contain;
      border: 1px solid $borderColor;
      border-radius: 4px;
    }
    .close-icon {
      position: absolute;
      top: -8px;
      right: 19px;
      background: $colorBorder;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      text-align: center;
      transition: 0.2 all ease-in-out;
      line-height: 16px;
      .mdi-trash {
        color: $primaryColor;
        width: 14px;
        height: 14px;
      }
    }
  }
}
