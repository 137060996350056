@import "../../Styles/variables";

.bidder-details {
  .primary-btn:hover {
    color: #fff;
  }

  .mdi-back {
    width: 16px;
    height: 16px;
    margin-right: 9px;
  }

  .details-section {
    padding: 0;

    .mdi-alert {
      width: 16px;
      height: 16px;
      color: $white;
      margin-right: 9px;
    }

    .reject {
      background-color: rgba(182, 30, 37, 1);
      color: $white;
      margin-right: 12px;
    }

    .approve {
      background-color: rgba(45, 167, 113, 1);
      color: $white;
    }

    .not-award {
      background-color: rgb(216, 142, 6);
      color: $white;
    }

    .bid-details {
      font-size: 20px;
      font-weight: 600;
      color: $white;
    }

    .card-wrapper {
      display: unset;
      justify-content: space-between;
      margin-bottom: 8px;

      .bid-before {
        display: flex;
        align-items: center;
        text-align: center;
      }
    }

    .media-icon {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 19px;
        margin-right: 16px;
      }
    }

    .btn-show {
      display: flex;
      color: $white;
      padding: 6px 12px;
      border: unset !important;
      background: transparent;

      img {
        width: 12px;
        margin-right: 8px;
      }

      .btn-secondary {
        border: 1px solid transparent;
        color: $white !important;
        background: $primary-gradient;
        border-radius: 5px;
        margin: 0px 12px 0px 12px;
        border: 1px solid #fff;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .padd-16 {
      padding: 16px;
    }

    .show-bid {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.07em;
    }
  }

  .headingButtons {
    display: flex;
    justify-content: inherit;
  }
}

.detail-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: $black;
  letter-spacing: 0.07em;
  min-width: 180px;
  margin-bottom: 24px;
}

.content-right {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: $black;
  letter-spacing: 0.07em;
}