@import "../../Styles/variables";
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ck-editor__editable {
  height: 200px !important;
}

.radio {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

td {
  padding: 5px !important;
}

input[type="number"] {
  -moz-appearance: textfield;
  width: 100%;
  border: 1px solid #cecece;
  padding-left: 10px;
}

.catalog-div {
  padding: 1rem 1rem 1rem;
}

.catalog-label {
  font-weight: inherit;
}

.react-tabs {
  width: 100%;
}

.dflex-box {
  display: flex;
  align-items: center;
}

.left {
  margin-top: 12px;
}

.outline {
  border: 1px solid $colorBorder;
  width: 20.3%;
  border-right: 0px;
}

.bordered-box {
  border: 1px solid $colorBorder;
  padding: 20px;
  background-color: #f9f9f9;
}

.btn-show {
  color: $secondaryColor;
  border: 1px solid $secondaryColor;
  background-color: $white;
  padding: 3px 10px;
  border-radius: 3px;
}

.boq-table {
  .left {
    margin-top: 10px;
  }
  .primary-btn {
    border: 1px solid #0065c3;
  }
  .primary-btn:hover {
    color: #fff !important;
  }
  .float-right {
    float: right;
  }
  .bi-trash:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    margin: 3px;
  }
  .start-right {
    margin-left: auto;
    width: fit-content;
    margin-bottom: 16px;
    .checkbox-label {
      margin-bottom: 4px;
    }
  }
  .sub-title {
    line-height: 1.2;
    letter-spacing: unset;
  }
  .mg-right-8 {
    margin-right: 8px;
  }
  .mg-top-btn {
    margin: 0;
    margin-bottom: 0px;
    background: none;
    padding: 0;
    font-size: 15px !important;
    border: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
  }
  .border-top-none {
    border: 1px solid $colorBorder;
    border-top: transparent;
    border-left: transparent;
  }
  .mg-btn {
    margin: 8px;
  }
  .st-line {
    border-bottom: 1px solid #e0e0e0;
  }
  .bi-trash {
    position: absolute;
    left: -15px;
    background: $colorBorder;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .center-icon {
    align-items: center;
  }
  .red-btn-2 {
    border: 1px solid;
    border-image: linear-gradient(to right, #b61e25 0%, #560d11 100%) 1;
    color: $secondaryColor;
    margin: 5px;
    background-color: $white;
    border-radius: 3px;
    padding: 8px, 20px, 8px, 20px;
  }
  .table-header {
    display: flex;
  }
  .red {
    color: $secondaryColor;
    font-weight: 700;
    font-size: 20px;
  }
  .small-box {
    padding: 20px;
    padding-bottom: 40px;
    width: 100%;
    border: 1px solid rgba(224, 224, 224, 1);
  }

  .text-center {
    margin: 0px;
    color: #fff;
    padding: 10px 8px;
    font-size: 14px;
    width: 100%;
    // background: linear-gradient(180deg, #4e83e9 0%, #17cfa4 100%);
    background: $primary-gradient-hor;
  }
  .p-0 {
    border-right: 1px solid #e9e9e9;
  }
  .d-flex {
    display: flex;
    text-align: center;
  }
  .bold-head {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
  }
  .check-section {
    display: flex;
    align-items: center;
    margin: 15px 0 0;
    .check-box {
      margin-right: 45px;
    }
    .check-box {
      margin-right: 45px;
      justify-content: center;
      display: flex;
    }
    .radio-box {
      justify-content: center;
      display: flex;
    }
  }
  .add-column-block {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 16px;
    margin-left: 50px;
    button {
      min-width: 150px;
    }
  }

  $font1: "Open Sans";

  @mixin center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  body {
    background: linear-gradient(90deg, #fff 50%, $primary-gradient 50.1%);
  }
  .buttons {
    display: block;
    margin: 0 auto;
  }

  .checkbox-label {
    margin-left: 5px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .sample-file {
    margin-top: -25px;
  }

  .form-label {
    .high-light {
      color: $secondaryColor;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
}

input[type="radio"]:hover {
  border: 1px solid $secondaryColor;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: $primary-gradient;
}

.Boq-details {
  display: flex;
  flex-direction: row-reverse;
  .boq-details-child {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding-top: 10px;
    font-size: 17px;
    width: 30%;
    .head-value {
      font-weight: 400;
      color: black;
    }
    .head {
      display: flex;
      gap: 50px;
      padding: 10px;
      background: rgba(0, 0, 0, 0.05);
      justify-content: space-between;

      .vat-checkbox {
        margin-right: 10px;
      }
    }
  }
}

.boq-custom-table {
  overflow: auto;
  .table {
    width: 100%;
  }
  th {
    background: $primary-gradient-hor;
    color: $white;
  }
  .addtional-field {
    min-width: 10rem;
    max-width: 10rem;
  }
  textarea {
    height: calc(100% - 24px) !important;
    padding: 4px 8px;
    &:focus-visible {
      outline: none;
    }
  }
  .form-control {
    font-size: 12px;
    &:focus {
      box-shadow: none;
    }
  }
  .td-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .checkbox-group {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    label {
      margin-bottom: 0;
      margin-left: 4px;
      font-size: 12px;
      color: $titleColor;
    }
  }
  .x-btn {
    background: none;
  }
  .column-1 {
    min-width: 5%;
    max-width: 5%;
  }
  .column-2 {
    min-width: 8rem;
    max-width: 8rem;
  }
  .column-3 {
    min-width: 12rem;
    max-width: 12rem;
  }
  .column-4 {
    min-width: 20rem;
    max-width: 20rem;
  }
  .column-5 {
    min-width: 24rem;
    max-width: 24rem;
  }
  .column-6 {
    min-width: 30%;
    max-width: 30%;
  }
}

@media (max-width: 992px) {
  .boq-table {
    .text-center {
      padding: 7px !important;
    }
  }
  .upper-big-box {
    overflow: scroll;
  }
  .bg-box {
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .btn-small {
    font-size: 14px !important;
  }
  .btn-show {
    margin: 0px;
  }
}

@media (max-width: 480px) {
  .bordered-box {
    overflow: scroll;
  }
  .boq-table {
    .table-header {
      white-space: nowrap;
    }
    .text-center {
      padding: 3px;
    }
  }
}
