@import "./variables";
@import "./mixin";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800;900&display=swap");

body {
  font-family: "Nunito Sans", sans-serif !important;

  .crisp-client .cc-tlyw[data-full-view=true] .cc-kxkl .cc-nsge,.crisp-client .cc-tlyw .cc-kxkl .cc-nsge{
  // bottom:76px !important;
}
}

p {
  margin-top: 0;
  margin-bottom: 0;
}
p + p {
  margin-top: 0 !important;
}
.mt-4{
  margin-top:4px
}

.modal-close-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  .modal-title {
    margin: 0px 0px 5px 0px;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
  }
  .mdi-close {
    height: 20px;
    width: 20px;
    color: #535050;
  }
}

.section-gap {
  margin: 50px 0;
}
.text-headers {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #303030;
  margin-top: 40px;
  text-align: center;
}

.error {
  text-align: left;
  color: #ff0000;
  font-size: 12px;
}

.input-content {
  border: 0.5px solid #919191;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 14px;
  width: 100%;
  background-color: #f8f1f1;
  border: 0.5px solid #818181;
}

.tender-bold {
  font-weight: 700;
  font-size: 12px;
  color: #303030;
  margin-left: 5px;
  letter-spacing: 0.07em;
}

.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.07em;
  color: $grayColor;
  text-align: initial;
}

.owl-carousel .owl-item {
  .quote-img {
    // display: inline-block !important;
    width: 69px;
    height: 53px;
  }
}

.MuiPagination-root {
  margin-top: 28px;
  margin-left: 55px;
  justify-content: center;
  display: flex;
}

.MuiPaginationItem-root.Mui-selected {
  // background: linear-gradient(94.87deg, #4e83e9 0.79%, #17cfa4 100%) !important;
  background: $primary-gradient !important;
  color: white !important;
}

.drop-title {
  font-weight: bold;
  font-size: 16px;
  color: $black;
  text-align: left;
  text-transform: capitalize;
}

.inputfield-item {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #000000;
}

.sub-item {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  text-align: center;
  color: #616161;
  margin-top: 8px;
}

.red-item {
  color: $primaryColor;
  margin-top: 25px;
}

.maintitle {
  @include title-style;
  font-size: 20px;
}
.search-btn {
  border: 1px solid transparent;
  color: $white !important;
  background: $primary-gradient;
  border-radius: 5px;
  margin: 0px 12px 0px 12px;
}
.title {
  @include title-style;
  font-size: 18px;
}
.border-line {
  height: 30px;
}
.align-center {
  align-items: center;
}
.pd-btn {
  padding: 8px 20px;
  border: 1px solid transparent;
}

.mg-btn-left {
  margin-left: 10px;
}

.btn-icon-size {
  width: 10px;
  margin-right: 6px;
}
.btn-img-icon-size {
  width: 15px;
  margin-right: 6px;
}
.primary-btn {
  border: 1px solid transparent;
  color: $white;
  padding: 6px 12px;
  background: $primary-gradient;
  border-radius: 5px;
  font-size: 14px;
  &:hover {
    opacity: 0.9;
  }
}
.transparent-btn {
  background: $white;
  color: $secondaryColor;
  border: 1px solid $secondaryColor;
  padding: 6px 12px;
  border-radius: 5px;
  font-size: 14px;
}

.save-bid-btn {
  background: $primary-gradient;
  color: $white;
  border: 1px solid $secondaryColor;
  padding: 6px 12px;
  border-radius: 5px;
  font-size: 14px;
}

.delete-btn {
  background: $white;
  color: $primaryColor;
  border: 1px solid $primaryColor;
  padding: 6px 12px;
  border-radius: 5px;
  font-size: 14px;
}
.css-319lph-ValueContainer {
  width: 280px;
}
.main-tab {
  .react-tabs__tab--selected {
    background-color: transparent;
  }
  .react-tabs__tab {
    font-size: 18px;
    font-weight: bold;
    color: #303030;

    img {
      vertical-align: inherit;
    }
  }
}
.medium-content {
  @include title-style;
  font-size: 16px;
  color: #303030;
  font-weight: 600;
  font-style: normal;
}

.d-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #717171;
  letter-spacing: 0.07em;
  text-align: initial;
}

.text-init {
  text-align: initial;
}

.total {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #000000;
}

// .owl-carousel .owl-item img {
//     display: initial !important;
//     width: 94px !important;

// }

.save-btn {
  background: $primary-gradient-hor;
  border-radius: 5px;
  color: $white;
  border: 1px solid #005cb6;
  padding: 8px 16px;
}

.btn-edit {
  padding: 7px 15px;
  font-size: 15px;
}
.large-content {
  @include title-style;
  font-size: 26px;
  font-weight: 600;
}

.small-content {
  @include title-style;
  font-size: 14px;
}

.content {
  @include title-style;
  font-size: 12px;
}

.download-content {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.07em;
  text-decoration-line: underline;
  color: $secondaryColor;
}
.show-bid {
  background-color: $white !important;
  color: $secondaryColor !important;
  border: 1px solid $secondaryColor !important;
  padding: 6px 12px;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(11, 11, 11, 0.75) !important;
  z-index: 1000;
}
.ReactModal__Content--after-open {
  width: 80%;
}

.btn-block {
  @include d-flex;
  button {
    margin-left: 8px;
  }
}
.btn-block-right {
  @include d-end;
  button {
    margin-left: 8px;
  }
}

.d-flex {
  @include d-flex;
}

.icon-bg {
  margin-right: 5px;
}
.e-notice-page {
  background: $bodyColor;
  .home-sidebar {
    position: relative;
    height: 100%;
    padding-bottom: 50px;
    .sidebar-sticky {
      position: sticky;
      top: 60px;
    }
  }
}


.text-btn {
  padding: 0;
  color: #fff;
  background: none;
  &:hover{ 
    text-decoration: underline;
  }
}

@media (max-width: 1920px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1850px;
  }
}

@media (max-width: 1650px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1550px;
  }
}

@media (max-width: 1420px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1360px;
  }
}

@media (max-width: 1380px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1300px;
  }
}
@media (max-width: 480px) {
  .col-sm-12 {
    width: 100% !important;
  }

  .col-sm-11 {
    width: 91.66% !important;
  }

  .col-sm-10 {
    width: 83.33% !important;
  }

  .col-sm-9 {
    width: 74.99% !important;
  }

  .col-sm-8 {
    width: 66.66% !important;
  }

  .col-sm-7 {
    width: 58.33% !important;
  }

  .col-sm-6 {
    width: 50% !important;
  }

  .col-sm-5 {
    width: 41.66% !important;
  }

  .col-sm-4 {
    width: 33.33% !important;
  }

  .col-sm-3 {
    width: 25% !important;
  }

  .col-sm-2 {
    width: 16.66% !important;
  }

  .col-sm-1 {
    width: 8.33% !important;
  }

  .col-sm-0 {
    width: 0;
  }
}

// @media (min-width: 200) {

//   .container,
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container-xl {
//     max-width: 1920px;
//   }
// }

@media (min-width: 576px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 560px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 750px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 980px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1303px;
  }
}

@media (min-width: 1330px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1238px;
  }
}

@media (min-width: 1630px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1600px;
  }
}

@media (min-width: 1830px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1800px;
  }
}
@media (max-width: 1650px) {
  .MuiPagination-root {
    margin-top: 10px;
    margin-left: 0px;
  }
}
