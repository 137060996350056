body {
  margin: 0;
  font-family:"Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:"Nunito Sans","source-code-pro", "Menlo", "Monaco", "Consolas", 'Courier New',
  monospace;
}
/* overide the z-index of the picker menu */
.rs-picker-menu{
  z-index:10 !important
}