.mobile-view-section {
    height: 100vh;
    width: 100vw;
    background-color: aliceblue;
    overflow: hidden;
    z-index: 9999;
    .bolpatra-logo {
        padding: 25px 0;
        width: 100%;
    }
    .content-section {
        padding: 80px 30px;
    }
    .mobile-title {
        color: #0052a7;
        font-size: 17px;
        font-style: normal;
        font-weight: 800;
        letter-spacing: .07em;
        text-align: center;
        text-transform: uppercase;
    }
    .mobile-desc {
        color: #606060;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: .07em;
        text-align: center;
    }
    .app-logo {
        width: 100%;
        margin-bottom: 15px;
    }
}