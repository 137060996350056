@import "../../Styles/variables";

.subscription-plan-section {
  .close-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 20px;
    border-bottom: 1px solid $outerlineColor;
  }

  .modal-title {
    font-size: 20px;
    font-weight: 600;
  }

  .payment-section {
    padding: 20px;
    .payment-method {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .bank-details {
      .sub-title {
        font-size: 15px;
        border-bottom: 1px solid $outerlineColor;
        color: $headingColor;
        letter-spacing: 0px;
      }
      .direct-bank-content {
        padding: 20px 10px;
        img {
          width: 100%;
          height: 80px;
          object-fit: contain;
        }

        .details-list {
          margin-top: 20px;
          label {
            font-size: 16px;
            font-weight: 800;
          }

          p {
            margin-bottom: 10px;
          }
        }

        .list-unstyled {
          margin-top: 20px;
          li {
            color: $headingColor;
          }
        }
      }
    }
  }

  .payment-modal-footer {
    padding: 20px;
    border-top: 1px solid $outerlineColor;
    text-align: initial;

    p {
      text-align: initial;
      font-size: 16px;
      color: $headingColor;
    }

    .crisp-img {
      height: 30px;
      width: 30px;
      border-radius: 50px;
      margin-left: 10px;
      object-fit: contain;
    }
  }
}
