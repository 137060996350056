@import "../../Styles/variables";

.etender-side-shimmer-page {

  .card-section {
    position: relative;
    border-bottom: 1px solid $borderColor;
    background: #fff;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
    .text-area {
      width: 100%;
    }
    .card-top {
      display: flex; 
      gap: 12px;
      .logo-section {
        min-width: 64px;
        height: 64px;
        background-color: #e0e1e5;
      }
    }
    
    .shimmer-text {
      color: unset;
      background-color: #e0e1e5;
      width: 60%;
      height: 20px;
      border-radius: 4px;
      margin-bottom: 6px;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
    .big-text {
      width: 100%;
      height: 18px;
    }
    .small-text {
      width: 100%;
      height: 16px;
    }
  }
  .shimmer-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -100%;
      bottom: 0;
      left: -100%;
      background-image: linear-gradient(
        to right,
        rgba(white, 0) 33.3%,
        rgba(white, 0.8),
        rgba(white, 0) 66.6%
      );
      animation: shimmer 1s infinite linear;
    }
  }

  @keyframes shimmer {
    from {
      transform: translateX(-33.3%);
    }
    to {
      transform: translateX(33.3%);
    }
  }
}
