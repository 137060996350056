@import "../../Styles/variables";

.accordians {
  position: absolute;
  padding: 20px;
  width: 100%;
  .accordion-input-section {
    max-height: 200px;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }

  .accordion-input-section.show {
    max-height: 1000px; 
  }
  .date-range-edit {
    .rs-picker-toggle-wrapper {
      width: 100% !important;
      max-width: unset;
    }

    .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
    .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
    .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
    .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
    .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
    .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
      box-shadow: none;
      border: 1px solid hsl(0, 0%, 80%);
    }

    .DateInput_input__focused {
      outline: none !important;
      box-shadow: none !important;
      border-color: transparent !important;
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
    .rs-btn-default {
      border: 1px solid hsl(0, 0%, 80%);

      border-radius: 4px !important;
    }

    .rs-picker:not(.rs-picker-disabled):hover,
    .rs-picker.rs-picker-focused {
      border-color: unset !important;
    }
  }

  .css-1pahdxg-control {
    box-shadow: none !important;
    border: 1px solid $greyColor !important;
  }
  .accordion-input-section {
    padding: 5px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .input-block {
      width: calc(20% - 12px);
    }
    .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
    .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
      color: $titleColor;
    }
  }
  .form-control {
    border: 1px solid $greyColor !important;
  }
  .hide-select-section {
    display: none;
    .select-tab {
      color: $titleColor;
    }
  }
  .css-1s2u09g-control {
    width: 180px;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
  }
  .searchwrapper-content {
    border: 1px solid $outerlineColor;
  }
  .filter-title {
    display: flex;
    align-items: center;
    letter-spacing: 0.07em;
    gap: 8px;
    img {
      height: 18px;
    }
    .reset-content {
      margin-left: 14px;

      color: $contentColor;
    }
  }
  .pd-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .form-inline img {
    position: absolute;
    left: -5px;
    width: 18px;
    height: 18px;
    top: 24px;
    margin-left: 27px;
  }

  .input-contents {
    border: 1px solid $outerlineColor;
    box-sizing: border-box;
    border-radius: 5px 5px 0 0;
    padding: 30px 50px;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.07em;
    width: 100%;
  }

  .border-line {
    border-bottom: 1px solid $contentColor;
    margin-bottom: 20px;
    height: 10px;
  }

  .d-flex {
    justify-content: space-between;

    .filter-options {
      margin-bottom: 6px;
    }
  }

  .filter-reset {
    border-radius: 0px 0px 5px 5px;
    padding: 20px;
    width: 100%;
  }

  .css-1okebmr-indicatorSeparator {
    width: 0px !important;
  }

  .form-control {
    width: 100% !important;
  }

  .btn-view-more {
    border-width: 1px;
    background-color: white;
    padding: 4px 16px;
    color: $secondaryColor;
    border: 1px solid $secondaryColor;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    .date-length {
      width: 148px;
    }
  }
  .css-1s2u09g-control {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .accordians {
    // .location-section{
    //   padding: 15px 15px;
    // }
    width: 100%;
    height: 150px;
    // overflow-y: scroll;
    .filter-content {
      white-space: nowrap;
    }
  }
}
@media (max-width: 480px) {
  .accordians {
    padding: 20px;
    width: 100%;
    height: 150px;
    .hide-select-section {
      display: block;
    }
  }
}
@media (max-width: 376px) {
  .accordians {
    width: 360px;
    padding: 25px;
  }
}
