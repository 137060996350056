@import "../../Styles/variables";

.bid-document-boq {
  .react-tabs {
    text-align: initial;
  }

  .react-tabs__tab-list {
    border-bottom: none;
    margin: 0 25px 0 0;
    // background-color: $higlightcolor;
    height: 60px;
    border-radius: 0 0 40px 40px;
    padding: 10px 0;
    margin: 0;
  }

  .react-tabs__tab {
    margin-right: 3px;
    border: 1px solid $colorBorder;
    background: $imgBackground;
    border-radius: 2px 2px 0px 0px;
  }

  .react-tabs__tab--selected {
    background: none;
    color: $red;
    // background: linear-gradient(180deg, #4E83E9 0%, #17CFA4 100%);
    background: $primary-gradient-hor;
    color: $white;
    border-radius: 2px 2px 0px 0px;
  }

  .tab-box {
    .btn {
      margin-top: 24px;
    }
    
    .primary-btn {
      background: $primary-gradient !important;
      font-size: 18px;
      color: #ffffff;
      border-radius: 5px;
      margin-left: 3px;
    }

    .primary-btn:active {
      font-size: 17px;
    }

    .white-btn {
      // background: linear-gradient(180deg, #4E83E9 0%, #17CFA4 100%);
      background: $primary-gradient-hor;
      color: #fff;
      border-radius: 2px 2px 0px 0px;
    }
    .pdf-content-section {
      margin-bottom: 25px;
    }
  }
}
