@import "../../Styles/variables";

.works-section {
  background: white;

  .work-source {
    min-width: 245px;
    display: flex;
    flex-direction: column;
    align-items: center;


    // &:nth-last-child(1) {
    //   min-width: auto;
    // }
  }

  .text-header {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: 303030;
    margin-top: 40;
    text-align: center;
  }

  .works-classify {
    display: flex;
    justify-content: center;
  }

  .card {
    border: 1px solid white;
    padding: 27px 0px;
    justify-content: center;
  }

  .text-header {
    margin-bottom: 40px;
  }

  
  .count {
    background: $primary-gradient;
    border-radius: 16px;
    padding: 20px 17px 15px 18px;
    color: $white;
    margin-left: 10px;
  }

  .works-classify {
    img {
      width: 180px;
      top: 80px;
      position: absolute;
    }

    .downarrow-img {
      img {
        width: 180px;
        top: 89px;
        position: absolute;
      }
    }

    .work-content {
      font-weight: 600;
      color: $titleColor;
      margin-top: 39px;
      font-size: 18px;
      line-height: 32px;
      text-align: center;
    }
  }
  @media (max-width: 992px) {
      
    .count {
      padding: 15px 15px 12px 16px;
    }
    .work-source {
      min-width: 180px;
    }

    img {
      width: 123px !important;
      top: 90px !important;
    }
    .work-content {
        font-size: 15px !important;
        margin-top: 30px !important;
        line-height: 25px !important;

    }
  }
  @media (max-width: 768px) {
      
    .count {
      padding: 12px 12px 9px 13px;
      font-size: 13px;
    }
    .work-source {
      min-width: 130px;
    }

    img {
      width: 85px !important;
      top: 94px !important;
    }
    .work-content {
        font-size: 11px !important;
        margin-top: 25px !important;
        line-height: 20px !important;
    }
  }
  @media (max-width: 550px) {
      
    .works-classify{
      flex-wrap: wrap !important;
    }
    .count {
      padding: 11px 11px 8px 12px;
      font-size: 11px;
    }
    .work-source {
      min-width: 130px;
    }
    

    img {
      width: 90px !important;
    }
    .work-content {
        font-size: 11px !important;
    }
  }
  @media (max-width: 480px) {
      
    .works-classify{
      flex-wrap: wrap !important;
    }
    .count {
      padding: 12px 12px 9px 13px;
      font-size: 11px;
    }
    
    .work-source {
      min-width: 130px;
      margin-bottom: 20px;
    }
    

    img {
      display: none;
    }
    .work-content {
        font-size: 11px !important;
        margin-top: 18px !important;
        line-height: 17px !important;
    }
  }
}
