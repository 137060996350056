@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
@import "../../Styles/variables";
.register-form {
  margin-bottom: 40px;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input:focus {
    outline: none;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .react-tel-input .selected-flag {
    background-color: #fff;
  }
  .react-tel-input .form-control {
    min-width: 100% !important;
    width: 100%;
  }
  // .arrow{
  //   left: 79px !important;
  // }
  input[type="radio"]:before {
    display: none;
  }
  .phone-no {
    position: absolute;
    top: 3px;
    color: $grayColor;
    right: 9px;
    width: 72%;
    border: 0;
  }
  .input-wrapper {
    position: relative;
  }
  .active {
    border: 2px solid #8098a5 !important;
  }

  .phone-area {
    position: absolute;
    top: 34px;
    right: 18px;
    width: 80%;
    border-radius: 3px;
    border: none;
  }

  li {
    list-style-type: none;
  }
  .primary-btn {
    width: unset !important;
  }
  .form-section {
    display: flex;
    width: 100%;
    margin: 0px 0px 24px 2px;
  }

  .line {
    border-bottom: 1px solid #d5d5d5;
    width: 100%;
    margin: 24px 1px;
  }
  .bi-calendar3 {
    position: absolute;
    top: 40px;
    right: 27px;
  }
  .bi-clock {
    position: absolute;
    top: 40px;
    right: 27px;
  }
  .red {
    color: rgb(228, 41, 41);
  }
  .outer-box {
    padding: 20px;
  }
  .float-right {
    float: right;
  }
  .mg-bottom {
    margin-bottom: 24px;
  }
  .d-flex {
    display: flex;
  }
  .right {
    text-align: right;
  }
  .head-titile {
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    text-align: left;
  }

  .left-box {
    border: 1px solid rgb(177, 176, 176);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 5px 0px 0px 5px;
    width: 100%;
    height: 100%;
    // background: linear-gradient(180deg, #4e83e9 0%, #17cfa4 100%);
    background: $primary-gradient-hor;
  }

  .outer-box {
    .personnel-section {
      justify-content: flex-start;
      gap: 16px;
      .radio-box {
        width: 25%;
      }
      .form-label {
        margin-bottom: 20px;
      }
    }
    .radio-box {
      cursor: pointer;
      border: 2px solid #fff;
      margin-bottom: 20px;
      width: 48%;
      border-radius: 5px;
      padding: 10px;
      label {
        margin-left: 20px;
      }
      .sub-title {
        margin-top: 8px;
      }
      .d-flex {
        justify-content: flex-start;
        label {
          margin-bottom: 0;
        }
      }
      input[type="radio"]:checked::after {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        color: #fbf7f7;
        position: relative;
        background-color: #4e83e9;
        content: "\2713";
        visibility: visible;
        border: 2px solid #4e83e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bolder;
        padding: 2px 0px 0px 0;
        font-size: 18px;
      }
    }
  }

  .right-box {
    border: 1px solid rgb(177, 176, 176);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 0px 5px 5px 0px;
    width: 100%;
    height: 100%;
  }

  .primary-btn {
    background: $primary-gradient !important;

    width: 141px;
    color: #ffffff;
    border-radius: 5px;
    padding: 8px, 20px, 8px, 20px;
  }
  .form-label {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: $fieldColor;
    text-align: left;
  }
  .note {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  .sub-content {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    margin-bottom: 12px;
  }
  .white-btn {
    background: #f8f8f8;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-left: 3px;
    text-align: left;
    padding: 8px, 20px, 8px, 20px;
  }
  .drop-zone {
    .inline-block {
      display: inline;
    }

    .drop-icon {
      text-align: center !important;

      img {
        width: 30px;
      }
      .column-line {
        display: flex;
        width: 100%;
      }
      .dzu-dropzone {
        background-color: $red;
        margin-bottom: 20px;
        overflow: hidden;
        color: $contentColor;
        font-weight: 500;
        font-size: 14px;
        padding-bottom: 24px;

        .dzu-icon {
          padding: 32px 0 18px 0;
        }

        span {
          text-decoration: underline;
          color: $borderColor;
        }
      }
      .venture-images {
        height: 30px;
        width: 30px;
        text-align: center !important;
      }
      .drag-file {
        color: $greyZone;
        font-weight: 500;
        font-size: 16px;
        font-style: normal;
        letter-spacing: 0.25px;
      }
    }
  }
}
.otp-confirmation-popup {
  margin-bottom: 40px;
  .otp-input-custom {
    width: 2.3em !important;
  }
  .title {
    text-transform: none;
  }
  .alert-section {
    display: flex;
    padding: 8px;
    background: #3b9bff29;
    border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 12px;

    .mdi-alert {
      width: 16px;
      height: 16px;
      color: $secondaryColor;
      margin-right: 9px;
    }

    .withdraw-bid {
      color: $secondaryColor;
    }
  }
}
@media screen and (max-width: 992px) {
  .register-form {
    .form-section {
      margin: 0px 0px 4px 2px;
    }
  }
}
