@import "../../Styles/variables";
.document-wrapper {
  .centered-flex-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn-download-as-pdf{
    position: sticky;
    top: 75px;
    z-index: 1;
    border: 1px solid gray;
    background: gray;
    float: right;
    padding: 10px;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    border-radius: 6px;
  }
  border: 1px solid #e0e0e0;
  padding: 16px;
  .venture-topic {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
  .padding-top {
    padding: 20px 0;
  }
  .pdf-content {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 16px;
    margin-top: 16px;
    .document-topic {
      text-decoration: none;
      color: $grayColor;
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-overflow: ellipsis;
      display: -webkit-inline-box;
      max-width: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin: 0 auto;
    }
    .document-title {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
    }
    .mg-top-20 {
      margin-top: 15px;
    }
    .box-content {
      position: relative;
      margin-top: 8px;
      width: 358px;
      height: 64px;
      background: $white;

      border: 1px solid$white;
      box-sizing: border-box;

      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      a {
        width: calc(100% - 85px);
      }
    }
    .img-content {
      padding: 12px 0px 20px 12px;
      img{
        height: 40px;
        width: 40px;
      }
    }
    .paragraph-content {
      font-family: "Noto Sans";
      margin: 0px 0px 0px 12px;
      .mb {
        color: $paragraphcolor;
      }
      img {
        position: absolute;
        top: 20px;
        right: 18px;
      }
    }
  }
  .content-section {
    .content-rt {
      font-weight: 700;
      margin-left: 7px;
    }
  }
  .pdf-content:nth-last-child(1) {
    border-bottom: unset !important
   ;
  }
}
.document-wrapper {
  .field-border {
    padding: 0 !important;
    padding-left: 0.5rem !important;
    border: 2px solid #ececec;
    border-bottom: 0;
    border-radius: 5px 5px 0 0;
    .w-auto {
      margin: 0;
    }
  }
  .body-field {
    border: 2px solid #ececec;
    border-top: 0;
    border-radius: 0 0 5px 5px;
  }
  .field-border {
    margin-top: 24px;
  }
  .pdf-content {
    border-top: 0;
    border-bottom: 0;
    margin: 0;
    padding: 10px 0 10px 10px;
    &:nth-of-type(3) {
      border-bottom: 2px solid #ececec;
    }
    &:nth-of-type(4) {
      border-bottom: 2px solid #ececec;
    }
  }
  .second-field {
    margin-top: 20px;
  }
}
