@import "../../Styles/variables";

.confirmation-popup {
  .info-logo {
    padding: 8px;
    background-color: #dfefff7d;
    width: fit-content;
    border-radius: 50%;
    border: 1px solid #dfefff;
    margin-bottom: 12px;
    .mdi-alert {
      width: 40px;
      height: 40px;
      background-color: #dfefff;
      border-radius: 50%;
      padding: 6px;
      margin-right: 0;
    }
  }
  .technical-note {
    display: flex;
    flex-direction: column;
    gap: 5px;
    textarea {
      width: 100%;
    }
  }
  .alert-section {
    display: flex;
    padding: 8px;
    background: #3b9bff29;
    border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 12px;

    .mdi-alert {
      width: 16px;
      height: 16px;
      color: $secondaryColor;
      margin-right: 9px;
    }

    .withdraw-bid {
      color: $secondaryColor;
    }
  }
  .mg-right-8 {
    margin-right: 8px;
  }
  .popup-btn {
    display: flex;
    justify-content: end;
    margin-top: 16px;

    .btn-cancel {
      padding: 8px 20px;
      background: $white;
      color: $secondaryColor;
      border: 1px solid $btn-color;
      border-radius: 5px;
      letter-spacing: 0.07em;
      margin-right: 12px;
    }

    .btn-withdraw {
      padding: 8px 20px;
      border-radius: 5px;
      // background: linear-gradient(180deg, #4E83E9 0%, #17CFA4 100%);
      background: $primary-gradient-hor;

      color: $white;
      border: 1px solid $btn-color;
      letter-spacing: 0.07em;
    }
  }
  .popup-body {
    padding: 16px 0;
    border-top: 1px solid rgb(194, 194, 194);
    border-bottom: 1px solid rgb(194, 194, 194);
  }
  .text-title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 18px !important;
    color: $titleColor;
    text-align: left;
  }

  .sub-header {
    font-weight: normal;
    font-size: 14px;
    color: $titleColor;
    text-align: left;
    margin-bottom: 16px;
  }

  .close-icon {
    float: right;
    cursor: pointer;
    .mdi-close {
      height: 18px;
      width: 18px;
      color: $inputBorder;
    }
  }

  .otp-input-custom {
    width: 2.3em !important;
  }
}
