@import "../../Styles/variables";

.enotice-banner {
  position: relative;
  background-color: $secondaryColor;
  color: #fff;
  border-radius: 0;
  .company-details-section {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    height: 100%;
    .logo-section {
      width: 100px;
      height: 100px;
      background-color: #fff;
      padding: 8px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      img {
        width: 100%;
      }
    }
    .company-details {
      .block-title {
        margin-bottom: 0;
      }
      img {
        width: 24px;
        height: 24px;
      }
      .banner-img {
        height: 28px;
        width: auto;
        margin-left: 8px;
      }
      .company-detail-content {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
