@import "../../Styles/variables";

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  display: unset;
  .comment-section {
    text-align: center;
  }
  .mg-bottom-20 {
    margin-bottom: 20px !important;
  }
  .medium-content {
    text-align: center;
    margin: 20px 0px -10px;
  }
  .content {
    text-align: center;
    font-size: 16px;
    margin: 20px;
    text-transform: none;
  }
  .quote-img {
    text-align: center;
    width: 20%;
  }
}

.bids-page {
  background: $imgBackground;
  padding: 24px 0 40px 0;
  .wraped {
    display: flex;
  }

  .card-bottom {
    margin-bottom: 8px;
  }
  .bids-tabsection {
    .outer-border {
      border: 1px solid $colorBorder;
      padding: 20px;
    }
    .card-bid {
      padding: 16px;
      box-shadow: 0px 10px 20px 0px #0000000a;

      .result-found {
        color: $secondaryColor;
      }
      .bid-card {
        border: none;
      }
      .card-section {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
          0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
      }
      .publisher-tab {
        min-width: 140px;
        .tab-title {
          text-align: center;
        }
        hr {
          margin: 6px 0;
        }
        .tab-content {
          font-size: 12px;
          line-height: 20px;
        }
      }
      .input-block {
        width: 250px;
      }
      .input-forms {
        border: 1px solid $outerlineColor;
        background: $white;
        border-radius: 4px;
        height: 40px;
        width: 250px;
        position: relative;
        padding-right: 15px;
        

        .image-block {
          position: relative;
        }
        .icon-section {
          position: absolute;
          top: 6px;
          right: 6px;
          cursor: pointer;
          .mdi-close {
            width: 20px;
            height: 20px;
          }
        }
        .form-control {
          height: 38px !important;
          padding: 0px 20px 0px 20px !important;
          &:focus {
            box-shadow: none;
          }
        }
        .input-contents {
          font-size: 14px;
        }
        .search-icon {
          position: absolute;
          height: 20px;
          width: 20px;
          top: 10px;
          left: 16px;
        }
        .content {
          width: 95%;
          border: none;
          margin-left: 26px;
          padding: 24px 33px;
        }
      }
    }
  }

  .react-tabs {
    text-align: initial;
  }

  .react-tabs__tab-list {
    border-bottom: none;
    margin: 0 25px 0 0;
    // background-color: $higlightcolor;
    border-radius: 0 0 40px 40px;
    padding: 10px 0 0;
    margin: 0;
  }

  .react-tabs__tab {
    margin-right: 3px;
    border: 1px solid $colorBorder;
    background: $imgBackground;
    border-radius: 2px 2px 0px 0px;
  }

  .react-tabs__tab--selected {
    background: none;
    color: $red;
    // background: linear-gradient(180deg, #4E83E9 0%, #17CFA4 100%);
    background: $primary-gradient-hor;
    color: $white;
    border-radius: 2px 2px 0px 0px;
  }

  .bid-title {
    font-weight: 600;
    font-size: 20px;
    color: $titleColor;
  }
}
@media screen and (max-width: 992px) {
  .bids-page {
    .card-wrapper {
      display: unset;
    }
    .react-tabs__tab {
      padding: 7px !important;
    }
    .react-tabs__tab {
      bottom: -5px;
    }
    .react-tabs__tab--selected {
      font-size: 16px;
    }
    .tender-contents {
      text-align: left;
    }
    .outer-border {
      padding: 15px !important;
    }
    .mybids-wrapper .card-section {
      padding: 10px;
    }
  }
  .show-bid {
    margin-left: 7px !important;
    margin-right: unset !important;
    padding: 6px 0px;
  }
  .mg-b-10 {
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .show-bid {
    padding: 3px 7px;
  }
}
@media screen and (max-width: 480px) {
  .bids-page {
    .react-tabs__tab--selected {
      font-size: 14px;
    }
    .d-flex {
      display: unset !important;
    }
    .card-wrapper {
      .wraped {
        justify-content: space-between;
        display: unset;
      }
    }
    .tid-content {
      display: unset;
    }
    .tender-flex {
      margin-top: 12px;
    }
    .outer-border {
      padding: 8px !important ;
    }
    .card-section {
      padding: 8px;
    }
  }
}
