@import "../../Styles/variables";

.boq-top-model {
  width: 100%;

  .transparent-btn {
    padding: 8px 12px;
  }
  
  .btn-primary {
    background-color: $secondaryColor;
    &:hover {
      background-color: #024891;
    }
  }

  .download-btn {
    background-color: $primaryColor;
    color: #fff;
    &:hover {
      background-color: #db0323;
    }
  }
  .excel-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .expire-bid {
    text-align: center;
    padding: 4rem;
  }
  .expiry-img {
    width: 45%;
  }
  .imageCenter {
    text-align: center;
    padding: 0.5rem;
  }

  .btn-option {
    margin-top: 15px;
    text-align: center;
  }

  .boq-popup-wrap-section {
    // max-height: 430px;
    // overflow-y: scroll;

    .boq-title {
      display: none;
      text-align: center;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .popup-sub-title {
      font-size: 18px;
      margin-bottom: 8px;
      color: $secondaryColor;
      font-weight: 700;
    }
  }

  .btn-option {
    display: flex;
    justify-content: center;
  }

  .letter-head-block {
    img {
      width: 100%;
      object-fit: cover;
      display: none;
    }
  }
}
.boq-wrapper {
  // .close-icon {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   cursor: pointer;

  //   .popup-title {
  //     font-size: 24px;
  //   }
  // }
  .ReactModal__Content {
    background-color: red;
  }
  box-sizing: unset !important;
  .boq-topic {
    margin: 0px 0px 5px 0px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  .boq-bill {
    border: 1px solid $colorBorder;
    padding: 16px;
  }

  .flex-items {
    display: flex;
    align-items: center;

    .procurement-items {
      font-weight: bold;
      font-size: 14px;
      color: $black;
      margin-right: 10px;
    }

    .btn-procurement {
      color: $primaryColor;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      );
      border-radius: 5px;
      padding: 8px 20px;
      border-width: 1px;
      border-image-slice: 1;
      border-style: solid;
      margin-right: 10px;
      //   border-image-source: linear-gradient(180deg, #4e83e9 0%, #17cfa4 100%);
      border-image-source: $primary-gradient-hor;
    }
    .text-center {
      text-align: center;
    }
  }

  .table-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #000000;
  }
  .boq-list {
    text-align: left;
  }

  .table {
    width: 100%;
    margin-top: 0 !important;

    .table-header {
      .category {
        min-width: 50px;
      }

      .s-no {
        min-width: 65px;
      }

      .item-desc {
        min-width: 200px;
      }

      .unit {
        min-width: 50px;
      }

      .quantity {
        min-width: 20px;
      }

      .rate {
        min-width: 80px;
      }

      .amount {
        min-width: 60px;
      }

      th {
        color: $white;
        // background: linear-gradient(180deg, #4E83E9 0%, #17CFA4 100%);
        background: $primary-gradient-hor;
      }
    }

    .vertical-content {
      vertical-align: middle;
    }
  }

  .unit-field {
    width: 60px;
    text-align: center;
    padding: 8px 10px;
    border-radius: 4px;
  }

  .amount-section {
    display: flex;
    align-items: center;
    text-align: right;
  }
  .input-text {
    width: 100%;
  }

  .scroll::-webkit-scrollbar {
    display: none;
  }

  .save-btn {
    margin-top: 34px;
  }
  .printSection {
    display: none;
  }
}

@media print {
  @page {
    size: 210mm 297mm;
    margin: 5mm 14mm 5mm 14mm;
  }
  .printSection {
    display: block;
  }

  .letter-head-block {
    img {
      display: block;
    }
  }
  .boq-title {
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    margin: 20px 0;
  }

  .table {
    width: 100%;
    margin-top: 0 !important;

    .table-header {
      .category {
        min-width: 50px;
      }

      .s-no {
        min-width: 65px;
      }

      .item-desc {
        min-width: 400px;
      }

      .unit {
        min-width: 50px;
      }

      .quantity {
        min-width: 20px;
      }

      .rate {
        min-width: 80px;
      }

      .amount {
        min-width: 60px;
      }

      th {
        color: $white;
        // background: linear-gradient(180deg, #4E83E9 0%, #17CFA4 100%);
        background: $primary-gradient-hor;
      }
    }

    .vertical-content {
      vertical-align: middle;
    }
  }

  .unit-field {
    width: 60px;
    text-align: center;
    padding: 8px 10px;
    border-radius: 4px;
  }

  .rate-amount {
    width: 150px;
    padding: 4px 10px;
    border-radius: 4px;
    margin: 4px 18px;
  }

  .input-text {
    width: 100%;
  }

  .popup-sub-title {
    font-size: 20px;
    text-align: center;
    margin: 4px 0;
    color: $secondaryColor;
  }
  .boq-wrapper {
    table {
      border-collapse: collapse;
      width: 100%;
    }
    th,
    td {
      border: 1px solid black;
      padding: 8px;
      text-align: left;
      font-size: 20px;
    }
  }
  .amount-section {
    display: flex;
    align-items: center;
  }
  .rate-amount {
    width: 150px;
    padding: 4px 10px;
    border-radius: 4px;
    margin: 4px 18px;
    // border: 1px solid #00000042;
  }

  .input-text {
    width: 100%;
  }

  .rate-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 56px;
  }
}
