@import "../../../Styles/variables.scss";

.forgot-pw {
  padding: 10px;
  .card {
    border: none;
  }

 
  .field-box {
    width: 100%;
    input {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      padding: 11px 15px;
      height: 47px;
      border: 1px solid #ced4da;
      font-size: 1rem;
    }
  }
  .material-textfield {
    margin-bottom: 8px;
      margin-top: 3px;
    position: relative;

    .error{
      margin: 4px 0;
    }
}
.text-field {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 22px;
  transform: translateY(-50%);
  background-color: white;
  color: $inputBorder;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: .1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}


.text-area {
  width: 100%;
}
input:focus+.text-field {
  color: $primaryColor;
  top: 0;
  transform: translateY(-50%) scale(.9);
}
.search-btn{
  margin: 0px 0px 0px 15px;
}
input:not(:placeholder-shown)+.text-field {
  top: 0;
  transform: translateY(-50%) scale(.9);
}
.blue-highlight{
  display: flex;
  align-items: center;
  border-radius: 2px;
  background-color: #e5f9f4;
  width: 100%;
  padding: 5px  8px;
  border-left: 2px solid #178E77;
  color: #178E77;
  margin-bottom: 10px;
}
  .bottom-btn {
    display: flex;
    justify-content: end;
    margin-top: 7px;
  }
  .text-title {
    text-align: unset;
    margin-bottom: 8px;
  }
  .padd-btn {
    padding: 7px 12px;
    border-radius: 5px;
  }
  .form-label {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: $fieldColor;
    text-align: left;
  }
  .mg-bottom-20 {
    margin-bottom: 15px;
  }
  .logo-size {
    width: 20px;
  }
}
