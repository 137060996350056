@import "../../Styles/variables";

.publisher-home-page {
  background: $imgBackground;

  position: relative;
  .mg-top-bid {
    margin-top: 150px;
  }

  .scroll-top-btn {
    position: fixed;
    bottom: 92px;
    right: 34px;
    width: 45px;
    height: 45px;
    z-index: 2;
    cursor: pointer;
    display: block;
  }
  .main-tab {
    position: relative;
    .switch-btns {
      position: absolute;
      right: 0;
      top: 4px;
    }
    .tab-head {
      display: flex;
      align-items: flex-end;
      gap: 8px;
    }
    .count {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 700;
      background: $primaryColor;
      color:$white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
    }
  }
  @media screen and (max-width: 992px) {
    .filter-search {
      width: 95%;
    }
  }
  .form-inline img {
    left: -5px;
    width: 14px;
    height: 14px;
    top: 19px;
    margin-left: 16 px;
  }
  .filter-search .input-contents {
    border-radius: 4px 4px 0 0;
    padding: 24px 33px;
    font-size: 15px;
  }
  .filter-reset {
    border-radius: 0px 0px 4px 4px;
    padding: 12px;
  }
  .css-14el2xx-placeholder {
    font-size: 13px;
  }
}
@media screen and (max-width: 992px) {
  .home-page {
    .filter-search {
      width: 92%;
    }
  }
}
@media screen and (max-width: 768px) {
  .home-page {
    .filter-search {
      .input-contents {
        padding: 23px 56px;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .home-page {
    .filter-search {
      width: 90%;
      .input-contents {
        padding: 23px 54px;
      }
    }
  }
}
@media screen and (max-width: 376px) {
  .home-page {
    .filter-search {
      left: 0px;

      .input-contents {
        padding: 23px 60px;
      }
    }
  }
}
