@import "../../Styles/variables";

.tab-box {
  margin: 16px;
  .btn {
    margin-top: 24px;
  }
  .prime-btn {
    padding: 8px 20px;
    font-size: 18px;
    color: #0052a7;
    border-radius: 5px 5px 0 0;
    margin-left: 3px;
    border-bottom: 3px solid #0052a7;
    background: #f8f8f8;
  }

  .white-btn {
    background: transparent;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-left: 5px;
    padding: 8px 20px;
    border-radius: 5px;
  }
}
