@import "../../Styles/variables";

.right-side-card-section {
  .right-side-card-content {
    background-color: #82828214;
    padding: 10px;
    border-radius: 5px;
    height: 200px;
    margin: 0 15px 20px 15px;
  }
}
