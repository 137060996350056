// $primaryColor: #a40606;
$primaryColor: #ff0026;
// $secondaryColor: #2760d8;
$secondaryColor: #0052a7;
// $primary-gradient: linear-gradient(94.87deg, #4e83e9 0.79%, #17cfa4 100%);
$primary-gradient: linear-gradient(94.87deg, #0052a7 0.79%, #0065c3 100%);
$primary-gradient-hor: linear-gradient(180deg, #0052a7 0.79%, #0065c3 100%);
$btn-color: #4e83e9;
$blue-bg: #e9effb;
$sucess-bg: #daecff;
$titleColor: #303030;
$contentColor: #919191;
$darkPrimary: #560d11;
$gradientprimary: linear-gradient(180deg, #b61e25 0%, #560d11 100%);
$contentGreen: #03964b;
$contentBlue: #0065c3;
$darkcontent: #00000080;
$higlightcolor: #f2e3e4;
$inputbgcolor: #f8f1f1;
$inputBorder: #818181;
$white: #fff;
$inputColor: #717171;
$outerlineColor: #e5e5e5;
$bodyColor: #f9f9f9;
$darkRed: #560d11;
$red: #b61e25;
$greyColor: #606060;
$lightGrey: #707070;
$paragraphcolor: #999999;
$lightRed: #b53535;
$greyLight: #505050;
$headingColor: #081f32;
$lightWhite: #ebeced;
$greenColor: #048244;
$lightGreen: #d4ffea;
$black: #000000;
$colorblack: #515151;
$lightPink: #faf0f1;
$borderColor: #d5d5d5;
// $grayColor: #616161;
$grayColor: #606060;
$darkBlack: #18191f;
$yellow: #c37f10;
$lightPinkColor: #fdf7e9;
$fieldColor: #616161;
$uploadFile: #363940;
$imgBackground: #f6f6f6;
$greyZone: #8098a5;
$colorBorder: #e0e0e0;
$borderLine: #c4c4c4;
$textColor: #363940;
$lightBlue: #488ae142;
$offWhite: #f2f2f2;
