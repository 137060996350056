@import "../../Styles/variables";
    
    .info-flex {
      display: flex;
      justify-content: initial;
      margin-bottom: 10px;
      .mdi-information {
        width: 20px;
        color: $yellow;
        position: absolute;
        margin-top: 8px;
        margin-left: 18px;
      }
    
      .info-content {
        background: $lightPinkColor;
        color: $yellow;
        padding: 8px;
        padding-left: 52px;
        font-size: 14px;
        border-left: 1px solid #c37f10;
      }
    }