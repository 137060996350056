@import "../../Styles/variables";

.add-venture {
  width: 100%;
  .border-line{
    height: 0 !important;
  }
  .error {
    justify-content: initial;
  }
  .venture-field {
    float: left;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.03em;
    color: $grayColor;
  }

  .file-uploadimg {
    img {
      width: 33px;
      height: 33px;
      margin-bottom: 19px;
    }
  }

  .save-btn {
    margin-top: 24px;
  }

  .upload-venture {
    font-weight: 600;
    font-size: 16px;
    color: $uploadFile;
    text-align: left;
    margin-bottom: 10px;
  }

  .joint-title {
    font-weight: 600;
    font-size: 14px;
    color: $red;
  }

  .drop-border {
    border: 1px dashed $inputBorder;
    padding: 30px;
    background: $imgBackground;
    border-radius: 8px;
  }

  .drop-zone {
    .inline-block {
      display: inline;
    }

    .drop-icon {
      img {
        width: 30px;
      }

      .dzu-dropzone {
        background-color: $red;
        margin-bottom: 20px;
        overflow: hidden;
        color: $contentColor;
        font-weight: 500;
        font-size: 14px;
        padding-bottom: 24px;

        .dzu-icon {
          padding: 32px 0 18px 0;
        }

        span {
          text-decoration: underline;
          color: $borderColor;
        }
      }
    }
   
  }

  .venture-img {
    text-align: center !important;
  }

  .drop-title {
    margin-top: 16px;
  }

  .drag-file {
    color: $greyZone;
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0.25px;
  }
}
