@import "../../Styles/variables";
@import "../../Styles/mixin";

.workpost-section {
  padding-top: 40px;

  .workpost-header {
    margin-bottom: 20px;
    .heading-title {
      margin-top: 0;
      line-height: 32.74px;
    }
  }

  .workpost-content {
    .card-section {
      border: none;
      padding: 20px;
      border-radius: 5px;

      .work-mid-section {
        @include d-flex;
        align-items: initial;

        .workpost-details {
          margin-left: 10px;

          .icon-bg {
            width: 20px;
            height: 20px;
            object-fit: contain;
            background: #a60000;
            color: white;
            padding: 6px 6px;
            border-radius: 4px;
          }

          .workpost-title {
            margin: 6px 0;
          }

          .description {
            color: $greyLight;
          }
        }
      }
    }
  }
}
