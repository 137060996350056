@import "../../Styles/variables";

.testimonials-wrapper {
  // background: linear-gradient(94.87deg, #4E83E9 0.79%, #17CFA4 100%);
  background: $primary-gradient;
  padding: 48px 52px 48px 53px;

  .header {
    font-weight: 600;
    font-size: 24px;
    color: $white;
    margin-bottom: 40px;
    text-align: center;
  }

  .comment-section {
    img {
      height: 64px;
      margin-right: 32px;
    }

    .quote-img {
      width: 59px !important;
      height: 52px;
    }
  }

  .content-images {
    .testimonails-img {
      width: 100px;
      margin-top: 24px;
    }
  }

  .testimonials-content {
    padding: 24px;
    display: flex;
  }

  .owl-theme {
    position: relative;
  }

  .owl-nav {
    .owl-prev {
      position: absolute;
      top: 64px;
      left: -43px;
      font-size: 38px !important;
      color: $white !important;
    }

    .owl-next {
      position: absolute;
      right: -42px;
      top: 75px;
      font-size: 38px !important;
      color: $white !important;
    }
  }

  .content-testi {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: initial;
    letter-spacing: 0.03em;
    color: $darkBlack;
  }
  @media (max-width: 480px) {
    .content-testi {
      font-size: 12px;
    }
  }
}
