@import "../../Styles/variables";
.register-info {
  // background: linear-gradient(180deg, #4e83e9 0%, #17cfa4 100%);
  background: $primary-gradient-hor;
  padding: 40px;
  height: 100%;
  .maintitle {
    margin-bottom: 20px;
    color: $white;
    font-weight: bold;
  }
  .img-content {
    display: flex;
    margin-bottom: 25px;
  }
  .img-logo {
    height: 30px;
    width: 30px;
    margin-right: 12px;
  }
  .small-content {
    color: $white;
    font-weight: 600;
  }
}
