.not-found {
  position: relative;
  background: white;
  .error-page {
    width: 50%;
    text-align: center;
    padding: 20px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .astro {
    width: 35%;
  }
}
