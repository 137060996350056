@import "../../Styles/variables";
@import "../../Styles/mixin";

.right-side-card-margin {
  .right-side-card-section {
    border-radius: 5px;
    .right-side-card-block {
      margin-bottom: 20px;
      img {
        width: 100%;
        // border-radius: 5px;
        object-fit: contain;
      }
    }
  }
}
