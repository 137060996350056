@import "../../Styles/variables";

.edit-section {
  padding: 0px 10px;
  overflow: none;
  .top-head {
    align-items: center;
    margin-bottom: 20px;
    position: relative;
  }
  .col-md-6 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .col-md-4 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .mdi-close {
    position: absolute;
    top: -4px;
    right: -12px;
  }
  .submit-section {
    display: flex;
    justify-content: center;
  }
  .form-section {
    display: flex;
    justify-content: center;
  }
  .update-topic {
    text-align: center;
    color: $secondaryColor;
    font-size: 26px;
    font-weight: 600;
  }

  input {
    font-size: 1rem;
    outline: none;
    border: 0.5px solid #919191;
    border-radius: 5px;
    padding: 0.5rem 0.7rem;
    color: #303030;
    transition: 0.1s ease-out;
    margin-bottom: 12px;
  }

  .submit-me {
    // background: linear-gradient(180deg, #4e83e9 0%, #17cfa4 100%);
    background: $primary-gradient-hor;
    border-radius: 5px;
    color: #fff;
    border: 1px solid;
    padding: 8px;
    margin-bottom: 40px;
    width: 24%;
    white-space: nowrap;
  }
}
