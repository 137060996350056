@import "../../Styles/variables";

.enotice-content {
  position: relative;
  margin: 24px 0;

  border-radius: 0;

  .enotice-details-section {
    border-radius: 8px;
    background-color: #fff;
    -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);

    .enotice-content-section {
      padding: 16px 24px;
      border-bottom: 1px solid $borderColor;
      overflow: auto;
    }

    .enotice-footer {
      padding: 16px 24px;
      display: flex;
      gap: 16px;
      justify-content: flex-end;
      align-items: center;

      .share-count {
        text-align: center;

        .share-title {
          font-size: 18px;
          font-weight: 600;
          color: $darkBlack;
        }

        .share-content {
          font-size: 12px;
        }
      }
    }
  }
}

.custom-mb-16 {
  margin-bottom: 16px;
}

.share-icon {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;

  .icon-div {
    width: 40px;
    height: 40px;
    background: $secondaryColor;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    // border: 1px solid transparent;
    &:hover {
      background: #fff;
      transition: all 0.2s ease-in-out;
      border: 1px solid $secondaryColor;

      .mdi-social {
        width: 20px;
        height: 20px;
        color: $secondaryColor;
      }
    }

    .mdi-social {
      width: 20px;
      height: 20px;
      color: #fff;
    }
  }

  .icon-messenger {
    background: $secondaryColor;
    /* Fallback color */
    // background: radial-gradient(110% 110% at 16.75% 100%, #0099FF 0%, #A033FF 60%, #FF5280 90%, #FF7061 100%);

    &:hover {
      border: 1px solid $secondaryColor;

      .mdi-social {
        color: $secondaryColor;
      }
    }
  }

  .icon-facebook {
    background: $secondaryColor;

    &:hover {
      border: 1px solid $secondaryColor;

      .mdi-social {
        color: #1877f2;
      }
    }
  }

  .icon-email {
    background: $secondaryColor;

    &:hover {
      border: 1px solid $secondaryColor;

      .mdi-social {
        color: $secondaryColor;
      }
    }
  }

  .icon-linkedin {
    background: secondaryColor;

    &:hover {
      border: 1px solid secondaryColor;

      .mdi-social {
        color: secondaryColor
      }
    }
  }

  .icon-viber {
    background: #795bef;

    &:hover {
      border: 1px solid #795bef;

      .mdi-social {
        color: #795bef;
      }
    }
  }

  .icon-whatsapp {
    background: #25d366;

    &:hover {
      border: 1px solid #25d366;

      .mdi-social {
        color: #25d366;
      }
    }
  }

  .icon-copy {
    background: $secondaryColor;

    .mdi-social {
      transform: rotate(135deg);
    }

    &:hover {
      border: 1px solid $secondaryColor;

      .mdi-social {
        color: $secondaryColor;
      }
    }
  }
}

.share-icon-section {
  .share-icon {
    gap: 16px;

    .icon-div {
      width: 48px;
      height: 48px;

      .mdi-social {
        width: 28px;
        height: 28px;
      }
    }
  }
}