@import "../../Styles/variables";

.margin-icon {
  margin-top: 4px;
}
.font-sample {
  font-size: 12px;
}

.box-box {
  padding: 20px 0;
}
.detail-content {
  text-align: left;
  // font-weight: bold;
}

.notes-content {
  margin-top: 20px;
  label {
    font-weight: bold;
  }
  .view-more-button {
    color: #005eb8;
    cursor: pointer;
  }
}
.note-box {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.boq-wrapper {
  .boq-bill {
    border: 1px solid $colorBorder;
    // padding: 16px;
  }
  .width-32 {
    width: 32%;
  }
  .position {
    justify-content: space-between;
    display: flex;
    padding: 20px 0;
    .transparent-btn {
      padding: 8px 16px;
    }
  }
  .padd-16 {
    padding: 16px;
  }
  .flex-items {
    display: flex;
    align-items: center;

    .procurement-items {
      font-weight: bold;
      font-size: 14px;
      color: $black;
      margin-right: 10px;
    }

    .btn-procurement {
      color: $primaryColor;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      );
      border-radius: 5px;
      padding: 8px 20px;
      border-width: 1px;
      border-image-slice: 1;
      border-style: solid;
      margin-right: 10px;
      // border-image-source: linear-gradient(180deg, #4e83e9 0%, #17cfa4 100%);
      border-image-source: $primary-gradient-hor;
    }
  }

  .table-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #000000;
  }
  .boq-list {
    text-align: left;
  }
  input[type="text"] {
    -moz-appearance: textfield;
    width: 100%;
    height: 38px;
    border: 1px solid #cecece;
    padding-left: 10px;
  }

  .fixed-rate {
    -moz-appearance: textfield;
    width: 100%;
    height: 38px;
    border: 1px solid #cecece;
    padding-left: 10px;
    padding-top: 10px;
    background: #e1e1e1;
  }

  .table {
    width: 100%;
    margin-top: 42px;

    .table-header {
      .category {
        min-width: 50px;
      }

      .s-no {
        min-width: 65px;
      }

      .item-desc {
        min-width: 400px;
      }

      .unit {
        min-width: 50px;
      }

      .quantity {
        min-width: 20px;
      }

      .rate {
        min-width: 80px;
      }

      .amount {
        min-width: 60px;
      }

      th {
        color: $white;
        // background: linear-gradient(180deg, #4e83e9 0%, #17cfa4 100%);
        background: $primary-gradient-hor;
      }
    }

    .vertical-content {
      width: 17%;
    }
  }
  .icon-section {
    position: absolute;
    right: 21px;
    cursor: pointer;
    background-color: $red;
    padding: 4px;
    .mdi-trash { 
      width: 16px;
      height: 16px;
      color: $white;
    }
  }
  .border-table {
    border: 1px solid black;
    width: 100%;
  }

  .unit-field {
    width: 60px;
    text-align: center;
    padding: 8px 10px;
    border-radius: 4px;
  }

  .table-bordered {
    width: 100%;
  }
  .rate-amount {
    width: auto;
    padding: 4px 10px;
    border-radius: 4px;
    margin: 4px 18px;
  }

  .input-text {
    width: 100%;
  }
  .scroll {
    overflow-x: scroll;
  }

  .scroll::-webkit-scrollbar {
    display: none;
  }

  .rate-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 56px;
  }

  .save-btn {
    margin: 0;
    float: unset;
  }

  .error {
    justify-content: left;
  }
  .custom-border-margin {
    border-top: 1px solid #d5d5d5;
    margin-top: 24px;
  }
  .boq-financial-block {
    .drop-title {
      margin-top: 16px;
    }
  }
}
@media (max-width: 992px) {
  .boq-wrapper {
    .table-bordered {
      width: 1100px;
    }
    .padd-16 {
      padding: 7px;
    }
    .boq-bill {
      padding: 7px;
    }
  }
}
