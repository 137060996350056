@import "../../Styles/variables";
.material-textfield {
  margin-bottom: 15px;
  position: relative;
}
.text-area {
  width: 100%;
}
.text-field {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 20px;
  transform: translateY(-50%);
  background-color: white;
  color: $inputBorder;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}
.eyeoff-outline {
  cursor: pointer;
  width: 18px;
  height: 15px;
  position: absolute;
  right: 15px;
  top: 9px;
  color: $contentColor;
}

.showPassword-label {
  color: #313030;
  margin-left: 10px;
}
.full-width {
  width: 100%;
}
.change-password-section {
  padding: 10px 0px;
  .top-head {
    align-items: center;
    margin-bottom: 20px;
    position: relative;
  }
  .mdi-close {
    position: absolute;
    top: -4px;
    right: -4px;
  }
  .update-password {
    text-align: center;
    margin-bottom: 20px;
    color: $secondaryColor;
    font-size: 26px;
  }
  .pd-btn {
    padding: 8px 20px;
  }
  input {
    font-weight: 600;
    font-size: 1rem;
    outline: none;
    border: 0.5px solid #919191;
    border-radius: 5px;
    padding: 0.5rem 0.7rem;
    color: #303030;
    transition: 0.1s ease-out;
    margin-bottom: 17px;
  }
  .submit-btn {
    display: flex;
    justify-content: center;
  }
  .new-password {
    display: flex;
    justify-content: center;
  }

  .submit-section-btn {
    // background: linear-gradient(180deg, #4e83e9 0%, #17cfa4 100%);
    background: $primary-gradient-hor;
    border-radius: 5px;
    color: #fff;
    border: 1px solid;
    padding: 8px;
    margin-bottom: 40px;
    width: 50%;
  }
}
