@import "../../Styles/variables";

.publisher-banner-shimmer-page {
  .card-section {
    position: relative;
    border-radius: 6px;
    width: 100%;
    padding: 24px 16px;
    .details-section {
      background: none;
      padding: 0;
      display: flex;
      align-items: flex-end;
      gap: 16px;
      .logo-section {
        min-width: 100px;
        height: 100px;
        background-color: #e0e1e5;
        border-radius: 4px;
      }
      .details {
        width: 100%;
        .small-text {
          width: 75%;
        }
      }
    }

    .banner-detail {
      display: flex;
      flex-direction: column;
      height: 100%;
      row {
        width: 100%;
      }
      .btn-section {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
        width: 100%;
        .btn-text {
          width: 20%;
          height: 20px;
        }
      }
    }
    .shimmer-text {
      color: unset;
      background-color: #e0e1e5;
      width: 50%;
      height: 15px;
      border-radius: 4px;
      margin-bottom: 15px;
      &:nth-last-child(1) {
        margin-bottom: 0px;
      }
    }
    .big-text {
      width: 60%;
      height: 20px;
    }
    .small-text {
      width: 100%;
    }
  }
  .shimmer-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -100%;
      bottom: 0;
      left: -100%;
      background-image: linear-gradient(
        to right,
        rgba(white, 0) 33.3%,
        rgba(white, 0.4),
        rgba(white, 0) 66.6%
      );
      animation: shimmer 1s infinite linear;
    }
  }

  @keyframes shimmer {
    from {
      transform: translateX(-33.3%);
    }
    to {
      transform: translateX(33.3%);
    }
  }
}
