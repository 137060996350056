@import "../../Styles/variables";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

.footer-content {
  background: $white;
  text-align: initial;
  padding: 20px 0;
  font-family: "Open Sans", sans-serif;
  .nav-link {
    padding: unset;
  }
  .bolpatra-logo {
    display: flex;
  }
  .footer-logo-section {
    margin-bottom: 20px;
    img {
      width: 150px;
    }
  }
  .mg-r {
    // margin-right: 20px;
  }
  .title {
    // color: red;

    color: $darkcontent;
  }

  .list-items {
    list-style: none;
  }

  .medium-content {
    color: $titleColor;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 10px;
    font-family: Open Sans;
    line-height: 30px;
  }

  p {
    margin-top: 11px;
  }
  ol {
    margin-left: -20px;
  }

  .img-block {
    height: 32px;
    margin-bottom: 20px;
  }
  .mg-24 {
    margin-bottom: 24px;
  }

  .title-space {
    margin-bottom: 20px;
  }
  .location-block {
    display: flex;

    .map-logo {
      margin-top: 18px;
      height: 16px;
      margin-right: 10px;
    }
  }

  .img-section {
    color: $btn-color;
    width: 195px;
    height: 33px;
    display: flex;
    margin-left: 12px;
    cursor: pointer;
  }
  .color-gradient {
    background: -moz-linear-gradient(top, #e72c83 0%, #a742c6 100%);
    background: -webkit-linear-gradient(top, #e72c83 0%, #a742c6 100%);
    background: linear-gradient(to bottom, #e72c83 0%, #a742c6 100%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .media-icon {
    margin: 20px 0;
  }
  .platforms {
    display: flex;
    align-items: center;
    gap: 8px;
    img {
      height: 42px;
      width: auto;
    }
  }

  .footer-area {
    margin-top: 33px;
    color: $darkcontent;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  @media (max-width: 992px) {
    .medium-content {
      font-size: 12px;
      margin-bottom: 10px;
    }
    @media (max-width: 768px) {
      .title-space {
        white-space: nowrap;
      }
    }

    .unset-margin-top {
      margin-top: unset;
    }
    .unset-margin-bottom {
      margin-bottom: unset;
      .list-height {
        margin: 7px !important;
      }
    }
  }
  @media (max-width: 480px) {
    display: none;
    .location-block {
      .map-logo {
        margin-top: 10px;
      }
    }

    .list-height {
      margin: 0 !important;
    }
    .medium-content {
      margin-top: 10px;
    }
    .img-section {
      margin-bottom: 12px;
      width: 170px;
    }
    .footer-area {
      display: flex;
      justify-content: center;
    }
  }
}
