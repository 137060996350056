@import "../../Styles/variables";

.contact-section {
  padding-top: 20px;
  width: 100%;
  border-top: 1px solid $borderColor;
  .card {
    padding: 0px !important;
    border: none;
    background: transparent;
    .row {
      width: 100%;
    }
    .company-details {
      text-align: end;
      .block-title {
        font-size: 20px;
        line-height: 32px;
        color: $titleColor;
      }
      .company-detail-content {
        font-size: 14px;
        line-height: 24px;
        color: $titleColor;
      }
      .icon-section {
        display: flex;
        gap: 4px;
        justify-content: end;
        img {
          width: 20px;
        }
      }
    }
  }
  .text-header {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: $titleColor;
  }
  .border-div {
    width: 200px;
    border: 2px solid $secondaryColor;
    margin: 12px 0 16px;
  }
  .text-content {
    font-size: 16px;
    line-height: 24px;
  }
  .mdi-icon {
    width: 40px;
    height: 40px;
    color: $black;
  }
}
