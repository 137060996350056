@import "../../Styles/variables";

.new-banner {
  height: 350px;
  position: relative;
  background-size: cover;
  background-image: linear-gradient(94.87deg,
      rgba(0, 82, 167, 0.42),
      rgba(0, 101, 195, 0.63)),
    url("../../images/banner.png");

  .form-control:focus {
    box-shadow: none;
  }

  .react-select-3-listbox {
    text-align: left;
    white-space: nowrap;
  }

  .center-div {
    width: 100%;
    text-align: center;
    padding-top: 80px;
  }

  .banner-img {
    img {
      width: 100%;
      height: 535px;
    }
  }

  .input-contents {
    font-size: 15px;
  }

  .centered {
    color: $white;
    margin-bottom: 50px;
  }

  .topic-one {
    text-align: center;
    font-weight: bold;
    font-size: 45px;
  }

  .paragraph-content {
    font-size: 20px;
    text-align: center;
  }

  .accordion-search-btn {
    padding: 9px 64px;
    background: $secondaryColor;
    border: none;
    color: $white;
    border-radius: 4px;

    &:hover {
      opacity: 0.9;
    }
  }



  .filter-search {
    position: relative;
    // border-bottom: 1px solid $contentColor;
    margin: 10px auto;
    width: 85%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: #fff;
    z-index: 10;

    .form-control {
      height: 38px !important;
      background: $white;
      padding: 0px 20px 0px 20px !important;
    }

    .date-length {
      color: grey;
    }

    .select-tab {
      border: none;
      width: 280px;

      .css-1s2u09g-control {
        cursor: pointer;
      }
    }

    .css-1s2u09g-control,
    .css-1pahdxg-control {
      background: $white;
    }

    .card {
      padding-top: 10px;
      border-radius: 15px;
      background: none;
      border: none;
    }

    .rs-picker-toggle-textbox,
    .rs-picker-toggle-read-only {
      background: $white;
      opacity: 1;
    }

    .searchwrapper-content {
      // border: 1px solid $outerlineColor;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      gap: 16px;

      .input-forms {
        border: 1px solid $outerlineColor;
        background: $white;
        border-radius: 4px;
        height: 40px;
        width: 95%;

        .image-block {
          position: relative;
        }

        .search-icon {
          position: absolute;
          height: 20px;
          width: 20px;
          top: 8px;
          left: 16px;
        }

        .search-bar {
          position: relative;

          .clear-search {
            position: absolute;
            right: 10px;
            top: 8px;
            height: 20px;
            width: 20px;
            cursor: pointer;
          }
        }

        .content {
          width: 95%;
          border: none;
          margin-left: 26px;
          padding: 24px 33px;
        }
      }
    }
  }

  .right-content {
    display: flex;

    .content-one {
      display: flex;
      align-items: center;
      color: $greyLight;
    }

    .btn-search {
      .search-btn {
        width: 122px;
        height: 41px;
        border: none;
        color: $white;
        background: $primary-gradient;
        border-radius: 5px;
        margin: 0px 12px 0px 12px;
      }
    }

    .accordian-icon {
      display: flex;
      align-items: center;

      .accordian {
        cursor: pointer;
      }
    }

    .btn-block {
      .primary-btn {
        img {
          height: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .centered {
      .topic-one {
        font-size: 32px;
      }
    }

    .filter-search {
      .form-control {
        width: 95%;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .centered {
      .topic-one {
        font-size: 32px;
      }
    }

    .filter-search {
      margin-top: 20px;

      .form-control {
        width: 400px;
      }
    }
  }

  @media (max-width: 768px) {
    .right-content {
      .btn-search {
        .search-btn {
          width: unset;
          height: unset;
          padding: 7px;
        }
      }
    }

    .filter-search {
      margin-top: 20px;

      .content {
        padding: 24px 51px !important;
      }

      .card {
        width: 100%;
      }

      .form-control {
        width: 222px;
        height: 38px;
      }
    }

    .centered {
      top: 43%;

      .topic-one {
        font-size: 25px;
      }

      .paragraph-content {
        font-size: 19px;
      }
    }

    .pd-5 {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }

  @media (max-width: 480px) {
    .right-content {
      .btn-search {
        .search-btn {
          width: 85px;
          height: 37px;
        }
      }

      .content-one {
        display: none;
      }
    }

    .input-forms:focus {
      outline: none;
    }

    .filter-search {
      margin-top: 10px;

      .select-tab {
        padding-left: 0%;
      }

      .select-section {
        display: none;
      }

      .form-control {
        width: 218px;
      }

      .card {
        width: 100%;
      }
    }

    .banner-img img {
      width: 100%;
      height: 335px;
    }

    .centered {
      top: 35%;

      .topic-one {
        font-size: 26px;
        white-space: nowrap;
      }

      .paragraph-content {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 376px) {
    #show_bg_2 {
      height: 248px;
    }

    .banner-img {
      img {
        height: 284px;
      }
    }

    .centered {
      top: 33% !important;

      .topic-one {
        font-size: 20px;
      }
    }

    .filter-search {
      .card {
        width: 360px !important;
      }

      .searchwrapper-content {
        z-index: 1111;

        .d-space-between {
          .input-forms {
            .search-icon {
              left: 9px;
            }

            .content {
              border: none;
              margin-left: 0px;
            }
          }
        }
      }
    }

    .right-content {
      .content-one {
        display: none;
      }

      .btn-search {
        .search-btn {
          width: 85px;
          height: 35px;
        }
      }
    }
  }

  .clear-btn {
    cursor: pointer;
  }
}