@import "../../Styles/variables";

.pricing-plan-section {
  padding: 40px;
  .pricing-plan-card {
    position: relative;
    border-radius: 16px;
    padding: 20px;
    border: 1px solid #6b6d710f;

    .transparent-btn:hover {
      color: $secondaryColor;
    }
    .best-value {
      clip-path: polygon(67% 0px, 78% 0px, 100% 41%, 100% 65%);
      background-color: #2760d8;
      padding: 10px;
      height: 145px;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;

      p {
        transform: rotate(42deg);
        position: absolute;
        right: 10px;
        text-align: center;
        top: 30px;
        color: #fff;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 12px;
      }
    }

    .pricing-top-section {
      padding: 20px;
      .text-headers {
        color: $headingColor;
        text-align: initial;
        margin-top: 0;
        span {
          color: $contentColor;
          font-size: 16px;
          margin-left: 10px;
        }
      }

      select {
        border: none;
        background-color: transparent;
        color: $secondaryColor;
        font-weight: 400;
        font-size: 24px;
        option {
          font-size: 18px;
        }
      }

      select:focus-visible {
        border: none;
        outline: none;
      }
    }

    .border-line {
      border-top: 1px solid $lightWhite;
      height: 5px;
    }
    .plan-title {
      color: $secondaryColor;
      font-weight: 400;
      font-size: 24px;
    }

    .pricing-plan-list {
      padding: 20px;
      min-height: 230px;

      .feature-section {
        min-height: 150px;
        margin-bottom: 10px;
      }
      .sub-title {
        font-size: 20px;
        margin: 5px 0;
        font-weight: 600;
        color: #437fbe;
        display: flex;

        label {
          width: 100%;
        }
      }
      .list-item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .icon-bg {
          color: $secondaryColor;
          margin-right: 10px;
          font-size: 20px;
        }
        .content {
          font-size: 16px;
          color: $greyLight;
        }
      }
    }

    .btn {
      width: 100%;
      padding: 10px 12px;
    }
  }

  .pricing-plan-card:hover {
    border: 1px solid #2760d8;
  }

  .pricing-plan-card:active {
    border: 1px solid #2760d8;
  }
}
@media screen and (max-width: 768px) {
  .pricing-plan-section {
    padding: 10px !important;
    .pricing-plan-card {
      margin-bottom: 16px;
    }
  }
}
