@import "../../Styles/variables";

.note {
  margin-bottom: 7px;
}

.title-note {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.W-100 {
  width: 100%;
}
.quotations-wrapper {
  .form-section {
    display: flex;
    width: 100%;
  }
  .border-box {
    border: 1px solid $colorBorder;
  }

  .line {
    border-bottom: 1px solid #d5d5d5;
    width: 100%;
    margin: 24px 1px;
  }
  .bi-calendar3 {
    position: absolute;
    top: 40px;
    right: 27px;
  }
  .bi-clock {
    position: absolute;
    top: 40px;
    right: 27px;
  }
  .red {
    color: rgb(228, 41, 41);
  }
  .outer-box {
    padding: 20px;
  }
  .box {
    padding: 20px;
    background: white;
    box-sizing: border-box;
  }
  .col-md-6 {
    margin-bottom: 24px;
  }
  .d-flex {
    display: flex;
  }
  .right {
    text-align: right;
  }
  .head-titile {
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    text-align: left;
  }
  .d-flex {
    display: flex;
  }

  .circle-x {
    border: none;
    background-color: transparent;
  }
  .outer-box {
    border: 1px solid rgb(177, 176, 176);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
  }
  .prime-btn {
    padding: 8px 20px;
    font-size: 18px;
    color: $secondaryColor;

    border-radius: 5px 5px 0 0;
    margin-left: 3px;
    border-bottom: 3px solid $secondaryColor;
    background: #f8f8f8;
  }
  .prime-btn:active {
    color: $secondaryColor;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
  }
  .bold-head {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
  }
  .small-box {
    padding: 20px;
    border: 1px solid rgba(224, 224, 224, 1);
  }
  .form-label {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: $fieldColor;
    text-align: left;
  }
  .note {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  .sub-content {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    margin-bottom: 12px;
  }
  .form-section {
    padding: 3px 2px 0 0;
  }
  .white-btn {
    background: transparent;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-left: 5px;
    padding: 8px 20px;
    border-radius: 5px;
    // border: 1px solid #8080802e;
  }
  .data {
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    background-color: #f9f9f9;
  }
  .box {
    padding: 20px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04);

    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.06);

    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  }
  textarea {
    resize: none;
    width: 100%;
    background: #ffffff;
    border: 1px solid #a5a5a5;
    box-sizing: border-box;
    border-radius: 4px;
  }
  input {
    resize: none;
    background: #ffffff;
    box-sizing: border-box;
    // border: none !important;
  }
  .react-datetime-picker__wrapper {
    border: none !important;
  }
  .react-datetime-picker__inputGroup__input {
    padding: 0px;
    border: unset;
  }

  .red-btn {
    border: 1px solid $secondaryColor;
    color: $secondaryColor;
    height: 35px;
    width: 173px;
    background-color: $white;
    left: 8px;
    top: 8px;
    border-radius: 3px;
    padding: 8px, 20px, 8px, 20px;
  }
  .d-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .red {
    color: $primaryColor;
    font-weight: 700;
    font-size: 20px;
  }
  .fixed {
    margin-left: 16px;
  }
  .input-area {
    width: 100%;
  }
  td {
    background: #f9f9f9;
  }
  .form-check-input {
    margin-left: unset;
  }
  .yellow-highlight {
    background-color: #fcf2dc;
    color: #b0800e;
    border-radius: 20px;
    padding: 3px 8px;
  }
  .red-highlight {
    background-color: #f5e4e2;
    color: #ae2116;
    border-radius: 20px;
    padding: 3px 8px;
  }
  .text-center {
    text-align: center;
  }
  .blue-highlight {
    background-color: #e5f9f4;
    color: #178e77;
    border-radius: 20px;
    padding: 3px 8px;
  }
  .table {
    width: 100%;
    margin-top: 22.5px;

    .table-header {
      .category {
        min-width: 50px;
      }
      th {
        color: $white;
        // background: linear-gradient(180deg, #4e83e9 0%, #17cfa4 100%);
        background: $primary-gradient-hor;
      }
    }
  }
}
@media (max-width: 768px) {
  .quotations-wrapper {
    .col-md-6 {
      margin-bottom: 17px;
    }
    .form-control {
      padding: 10px 5px;
      font-size: 14px;
    }
  }
  .form-check-label {
    font-size: 14px;
  }
}
@media (max-width: 1650px) {
  .container {
    max-width: 1440px;
  }
}
@media (max-width: 480px) {
  .quotations-wrapper {
    .head-titile {
      font-size: 18px;
    }
    .prime-btn {
      font-size: 16px;
      padding: 6px;
    }
    .white-btn {
      font-size: 16px;
    }
  }
}
