@import "../../Styles/variables";

.about-section {
  background: #f6f6f6;
  padding: 0 0 40px 0;
  .card {
    padding: 16px 24px;
    text-align: center;
    align-items: center;
  }
  .text-header {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: 303030;
    margin-top: 40;
    text-align: center;
  }
  .border-div {
    width: 200px;
    border: 2px solid $secondaryColor;
    margin: 12px 0 16px;
  }
  .text-content {
    font-size: 16px;
    line-height: 24px;
  }
  .about-body {
    margin: 20px 0;
    width: 100%;
    .icon-section {
      min-width: 48px;
      height: 48px;
      background: $lightWhite;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .mdi-icon {
        width: 24px;
        height: 24px;
        color: $secondaryColor;
      }
      .about-img {
        width: 36px;
      }
    }
    .text-section {
      .text-title,
      .text-content {
        text-align: left;
      }
      .text-title {
        font-size: 14px;
        color: $greyColor;
        line-height: 24px;
      }
      .text-content {
        font-size: 16px;
        color: $black;
      }
    }
  }
}
