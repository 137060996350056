.account-deletion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  .headline {
    text-align: center;
  }

  .sub-title {
    text-align: center;
    margin-bottom: 10px;
  }

  form {
    width: 55%;
  }
  
  .card {
    margin: 30px;
    padding: 20px;
    width: 50%;
  }
}
