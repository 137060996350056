@import "../../Styles/variables";

.tender-table {
  .table {
    border-spacing: 2px;
    border-collapse: unset !important;

    thead {
      background: #F1F0F8;

      th {
        padding: 5px;
      }
    }

    tbody {
      ul {
        padding-left: 15px;
      }

      td {
        font-size: 13px;
        font-weight: 600;
      }

      tr:nth-child(even) {
        background-color: #F1F0F8;
      }

      .table-row {
        cursor: pointer;
        transition: all ease-in-out 0.2s;

        &:hover {
          background-color: #eaeaea;
          transition: all ease-in-out 0.2s;
        }
      }
    }
  }

  .tender-title {
    cursor: pointer;
    color: $secondaryColor;
    font-weight: 700;
  }

  .danger-text {
    color: $red;
    font-size: 11px;
  }

  .category-tag {
    padding: 3px 8px;
    background-color: #d5d9e2;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 4px;
    margin-right: 4px;
  }

  .primary {
    color: $secondaryColor;
  }

  .secondary {
    color: $primaryColor;
  }

  .tertiary {
    color: #009688;
  }

  .quaternary {
    color: #6600cc
  }

  .quinary {
    color: #ff007f
  }

  .senary {
    color: #ff9900
  }

  .action-icon-block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .action-icon {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background: #ddd;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      img,
      svg {
        height: 18px;
        width: 18px;
        color: #666;
      }

      &:hover {
        background: $secondaryColor;

        img,
        svg {
          color: $white;
        }
      }
    }
  }
}