@import "../../Styles/variables";

.success-box {
  display: flex;
  background-color: $sucess-bg;
  color: $secondaryColor;
  padding: 5px 10px;
  font-size: 14px;
  margin-bottom: 16px;
}
.bidders-list-popup {
  .close-icon {
    float: right;
  }
  .text-title {
    font-size: 20px;
    color: $black;
    font-weight: 500;
  }
  .content {
    font-size: 16px;
    color: $black;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .popup-content-section {
    margin: 16px 0 24px;
    .checkbox-list {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 8px;
      padding: 12px 16px;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid $borderColor;
      cursor: pointer;
      transition: 0.2s all ease-in-out;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
      &:hover, &.selected {
        border-color: $secondaryColor;
      }
      label {
        font-size: 14px;
        color: $black;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }
  .popup-btn {
    border-top: 1px solid $borderColor;
    padding-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  .info-logo {
    padding: 8px;
    background-color: #dfefff7d;
    width: fit-content;
    border-radius: 50%;
    border: 1px solid #dfefff;
    .mdi-alert {
      width: 40px;
      height: 40px;
      background-color: #dfefff;
      border-radius: 50%;
      padding: 6px;
      margin-right: 0;
    }
  }
  .info-title {
    margin-bottom: 12px;
    .info-logo {
      margin-bottom: 12px;
    }
  }
  .popup-body {
    padding: 16px 0;
    border-top: 1px solid rgb(194, 194, 194);
    .check-section {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }
}
