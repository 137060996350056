@import "../../Styles/variables";
.tender-action-section {
  display: flex;
  
  .close-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .boq-topic {
      margin: 0px 0px 5px 0px;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
    }
    .mdi-close {
      height: 20px;
      width: 20px;
      color: #535050;
    }
  }
  .btn-icon-size {
    object-fit: contain;
    width: 20px;
    color: $primaryColor;
    cursor: pointer;
  }
}
