.etender-shimmer-page {
    margin: 30px 0;
   
    .card-section {
      position: relative;
      -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border-radius: 6px;
      width: 100%;
      padding: 20px;
      margin-bottom: 8px;
      .text-area {
        width: 100%;
      }
  
      .shimmer-text {
        color: unset;
        background-color: #e0e1e5;
        width: 50%;
        height: 20px;
        border-radius: 4px;
        margin-bottom: 15px;
      }
      .big-text {
        width: 100%;
        height: 20px;
      }
      .text-40 {
        width: 40%;
      }
      .card-top {
        display: flex;
        justify-content: space-between;
      }
    }
    .shimmer-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-x: hidden;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -100%;
        bottom: 0;
        left: -100%;
        background-image: linear-gradient(
          to right,
          rgba(white, 0) 33.3%,
          rgba(white, 0.8),
          rgba(white, 0) 66.6%
        );
        animation: shimmer 1s infinite linear;
      }
    }
  
    @keyframes shimmer {
      from {
        transform: translateX(-33.3%);
      }
      to {
        transform: translateX(33.3%);
      }
    }
  }
  