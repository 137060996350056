@import "../../Styles/variables";

.amendment-box {
  padding-left: 9px;
  border: 1px solid #0040ffa3;
  padding-right: 9px;
  border-radius: 16px;
}
.btn-mg {
  margin: 8px 0;
}
.amendment-text {
  font-size: 14px;
  font-weight: bold;
  color: #0040ffa3;
}
.badge-parent {
  position: relative;
  margin-left: 15px;
}
.badge-child {
  right: 5rem;
}

.mybids-wrapper {
  .mdi-close {
    height: 18px;
    width: 18px;
    color: $primaryColor;
    margin-right: 3px;
  }

  .border-line {
    height: 16px;
  }
  .pencil-square {
    height: 18px;
    width: 18px;
    margin-right: 3px;
  }

  .resubmit-icon {
    height: 14px;
    width: 14px;
    margin-right: 4px;
    margin-bottom: 4px;
  }
  .search-btn {
    margin: 0;
  }
  .btn-radius {
    padding: 6px 12px;
    display: inline-block;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    z-index: 0;
    border: 1px solid transparent;
    &::after {
      content: "";
      position: absolute;
      /* specify the value of border width here */
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      /* --- */
      // background: linear-gradient(94.87deg, #4e83e9 0.79%, #17cfa4 100%);
      background: $primary-gradient;
      background-color: #fff;
      border-radius: 5px;
      box-sizing: border-box;
      z-index: -1;
    }
  }
  .card-section {
    padding: 20px 20px 5px 20px;
    box-shadow: 5px 10px #888888;
    margin-bottom: 0px;
    white-space: normal;
  }
  .blue-highlight {
    display: flex;
    align-items: center;
    border-radius: 2px;
    background-color: #e5f9f4;
    width: fit-content;
    padding: 3px 8px;
    border-left: 2px solid #178e77;
    color: #178e77;
    margin-bottom: 10px;
  }
  .blue-icon {
    color: #178e77 !important;
  }
  .icon-size {
    float: left;
  }
  .mdi-alert {
    width: 16px;
    height: 16px;
    color: $red;
    margin-right: 9px;
  }
  
  .red-highlight {
    display: flex;
    align-items: center;
    background-color: #fbeae8;
    color: #ae2116;
    width: fit-content;
    padding: 3px 8px;
    border-radius: 2px;
    border-left: 2px solid #ae2116;
    margin-bottom: 10px;
  }
  .tag-info {
    display: flex;
    align-items: center;

    img {
      width: 12px;
      margin-right: 7px;
    }

    .budget-icon {
      width: 12px;
      height: 8px;
    }

    .tender-contents {
      font-size: 12px;
      font-weight: 600;
      color: $titleColor;
    }
  }

  .filter-search {
    position: absolute;
    width: 91%;
    left: 55px;
    top: 329px;
  }

  .tid-content {
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.07em;

    .tender-header {
      display: flex;
      letter-spacing: 0.07em;
    }
  }

  .sub-title {
    margin-top: 12px;
  }

  .border-line {
    border-right: 1px solid $borderColor;
    margin-left: 12px;
    margin-right: 14px;
  }

  .tender-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: $secondaryColor;
    text-align: initial;
    letter-spacing: 0.07em;
    margin-top: 16px;
    .tag {
      color: $secondaryColor;
      padding: 2px 8px;
      border: 1px solid $secondaryColor;
      background-color: #cce5ff;
      font-size: 12px;
      border-radius: 12px;
      margin-left: 8px;
    }
  }

  .tender-border {
    border-bottom: 1px solid $outerlineColor;
    margin-bottom: px;
    margin-top: 16px;
  }

  .days-remain {
    display: flex;
    align-items: center;
  }

  .border-line {
    border-right: 1px solid $contentColor;
  }

  .days-count {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.07em;
    background: rgba(39, 96, 216, 0.1);
    color: $secondaryColor;
    padding: 5px 12px;
    margin-left: 10px;
    border-radius: 5px;
  }

  .media-icon {
    display: flex;
    align-items: center;
    margin-right: 20px;
    gap: 0.3rem;

    img {
      width: 20px;
      height: 19px;
      margin-right: 16px;
    }
  }

  .show-bid {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.07em;
  }

  .btn-show {
    display: flex;

    padding: 6px 12px;

    .show-bid {
      background-color: $white;
      border: 1px solid;
      padding: 6px 12px;
    }

    .btn-secondary {
      color: $red;
    }

    .mdi-close {
      width: 14px;
      height: 14px;
      margin-right: 13px;
    }
  }
  .ml-22 {
    margin-left: 22px;
  }
  .mr-0 {
    margin-right: 0;
  }
  .btn-bid {
    display: flex;
    align-items: center;
    color: $white;

    .btn-secondary {
      color: $red;
    }

    .pencil-square {
      width: 14px;
      margin-right: 7px;
    }

    .mdi-eye {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }

    .three-dots {
      background-color: transparent;
      border: none;
    }

    .three-dots:after {
      cursor: pointer;
      color: #fcc;
      content: "\2807";
      font-size: 20px;
      padding: 0 5px;
    }

    .show-bid {
      background-color: $white;
      border: 1px solid;
      margin-right: 15px;
    }
  }

  .card-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    .bid-before {
      display: flex;
      text-align: center;
      align-items: center;
    }
  }

  .tid-no {
    font-weight: 600;
    font-size: 12px;
    color: $grayColor;
  }

  .map-icon {
    margin-right: 0 !important;
  }
  // .delete-btn {
  //   margin: 10px 10px !important;
  // }

  .mg-btn {
    margin: 10px 10px;
  }
  .download-flex {
    display: flex;
    align-items: center;

    .mdi-download {
      width: 14px;
      height: 14px;
      color: $secondaryColor;
      align-items: center;
      margin-right: 11px;
    }
  }
  .d-flex {
    justify-content: unset;
  }

  .tender-flex {
    display: flex;
  }
}
.btn-mg {
  padding: 7px 11px;
  margin-left: 7px;
  font-size: 14px;
}
.delete-btn {
  color: $primaryColor !important;
  border-color: $primaryColor !important;
  margin-left: 10px !important;
  margin-right: 0 !important;
}

.mybid-group {
  .time-date {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    background: $grayColor;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2px;
    text-align: initial;
    margin-left: 15px;
    margin-top: 8px;
  }
}

@media (max-width: 992px) {
  .mybids-wrapper {
    .card-wrapper {
      display: flex !important;
    }
  }
}
@media (max-width: 768px) {
  .mybids-wrapper {
    .d-flex {
      justify-content: space-between;
    }
    .card-wrapper {
      display: unset !important;
    }
  }
}
@media (max-width: 480px) {
  .mybids-wrapper {
    .tender-header {
      display: unset !important;
    }

    .tender-flex {
      margin-top: 8px !important;
    }
    .d-flex {
      float: unset;
    }
    .days-count {
      margin-left: unset;
      margin-top: 8px;
    }
    .border-line {
      border-right: none;
    }
    .btn-bid {
      margin-top: 4px;
      padding: 0;
    }
    .tender-border {
      margin-bottom: 3px;
    }
    .fixed-position {
      position: relative;
      float: right;
      .media-icon {
        position: absolute;
        top: -29px;
        right: -14px;
      }
    }
    .show-bid {
      margin-right: unset !important;
    }
    .border-line {
      margin-left: 0;
      margin-right: 0;
    }
    .bid-before {
      justify-content: space-between;
    }
    .mg-left {
      margin-left: 8px;
    }
  }
}
