@import "../../Styles/variables";


.clients-section {
    padding-top: 40px;
    background: #fff;

    .client-header {
        font-weight: 600;
        font-size: 24px;
        color: $darkBlack;
        margin-bottom: 60px;
        text-align: center;

    }

    img {
        width: 78px;
    }



    .clients-source {
        img {
            margin-right: 70px;
        }
    }

    .owl-nav {
        .owl-prev {
            position: absolute;
            left: 2px;
            bottom: 31px;
            font-size: 28px !important;

        }

        .owl-next {
            position: absolute;
            right: 2px;
            bottom: 31px;
            font-size: 28px !important;

        }
    }

    .owl-item {


        .active {
            width: 70px;
        }


    }

    .company-images {
        width: 100px !important;
        margin: 0px 50px;
    }








}