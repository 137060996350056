@import "../../Styles/variables";

.react-tabs__tab-list {
  border-bottom: 2px solid #aaa;
  margin: 0 0 24px;
  padding: 0;
}
.react-tabs__tab--selected {
  border-bottom: 3px solid $red;
  color: #dc1d29;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
  border-top: white;
  border-left-color: white;
  border-right-color: white;
}
.register-form li {
    list-style-type: none;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
}

