@import "../../Styles/variables";
.navActive {
  margin-left: 5px;
  display: flex;
  gap: 3.5rem;
  font-weight: bold;
}

.navbar-section {
  position: sticky;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  .nav-active {
    display: flex;
    justify-content: space-around;
  }

  .orange-btn {
    min-width: 95px;
  }
  .hide {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .sticky-header {
    box-shadow: 0px 1px 6px #cccccc87;
  }
  nav {
    .navbar-brand {
      margin-right: 50px;
      margin-top: -3x;
      img {
        width: 40px;
      }
    }
    .navbar-nav .dropdown-menu {
      position: absolute;
      z-index: 11111;
      left: -19px;
    }
    .navbar-nav {
      display: flex;
      justify-content: space-between;
      .active {
        a {
          color: black !important;
          font-weight: 600;
          font-size: 16px;
        }
      }
      .nav-link {
        white-space: nowrap;
        margin-right: 20px;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        color: $titleColor;
        text-decoration: none;
      }
    }
    .navbar-collapse {
      display: flex;
      justify-content: space-between;
    }
    .active {
      color: $secondaryColor;
      text-decoration: none;
    }

    .form-inline {
      width: 50%;
      position: relative;

      input {
        width: 100%;
        padding-left: 35px;
      }

      img {
        position: absolute;
        left: 10px;
        width: 18px;
      }
    }
  }

  nav {
    padding: 9PhomPpx 0;

    .navbar-brand {
      margin-right: 50px;

      img {
        width: 192px;
      }
    }

    .navbar-nav {
      .active {
        a {
          font-weight: 600;
          font-size: 16px;
        }
      }

      .nav-link {
        // padding: 8px 20px;
        margin-right: 40px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: $titleColor;
      }
    }
    .not-active {
      color: black;
    }
    .not-active:hover {
      text-decoration: none;
    }
    .active {
      color: $secondaryColor;
    }

    .form-inline {
      width: 50%;
      position: relative;

      input {
        width: 100%;
        padding-left: 35px;
      }

      img {
        position: absolute;
        left: 10px;
        width: 18px;
      }
    }
  }
}
@media (max-width: 992px) {
  .navbar-section {
    .navbar-nav {
      align-items: center !important;
    }
    nav {
      padding: 6px 0 !important;
      .small-content {
        line-height: 16px;
      }
      .orange-btn {
        padding: 5px 20px;
      }
      .orange-line-btn {
        padding: 5px 20px;
      }
      .nav-link {
        font-size: 15px;
      }
    }
  }
}
.box-nav {
  margin-bottom: 50px !important;
}

@media (max-width: 1440px) {
  .navbar-section {
    nav {
      .form-inline {
        width: 56%;
      }
    }
  }
}

@media (max-width: 1260px) {
  .navbar-section {
    nav {
      .form-inline {
        width: 30%;
      }
    }
  }
}

@media (max-width: 992px) {
  .navbar-section {
    nav {
      .form-inline {
        width: 28%;
      }
    }
  }
}
@media (max-width: 768px) {
  .navbar-section {
    nav .orange-btn {
      padding: 6px 20px;
    }
    .navbar-nav {
      flex-direction: unset;
    }
  }
}

@media (max-width: 991px) {
  .navbar-section {
    .hide {
      display: none;
    }
    .show {
      display: flex;
    }
  }
}

@media (max-width: 700px) {
  .navbar-section {
    .navActive {
      gap: 2.3rem;
    }
  }
}

@media (max-width: 600px) {
  .navbar-section {
    .hide {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .topheader-section {
      margin-top: 10px;
    }
  }
}

@media (max-width: 500px) {
  .navbar-section {
    .navActive {
      gap: 2rem;
    }
  }
}

// @media (max-width:400px){
@media (max-width: 450px) {
  .navbar-section {
    visibility: hidden;
    .hide {
      align-items: flex-start;
    }
    .navActive {
      flex-direction: column;
    }
  }
}

@media (max-width: 480px) {
  .navbar-section nav .orange-btn {
    padding: 6px 12px;
  }

  .navbar-section {
    font-size: 14px;
    .dropdown-item {
      padding: 3px 10px;
    }
    .user-name {
      font: 14px;
    }
    .icon-text-section {
      font-size: 14px;
    }
    .search-btn {
      font-size: 14px;
    }
    .topheader-section {
      align-items: center;
    }
    .navbar-nav {
      .nav-link {
        font-size: 14px !important;
        margin-right: 32px !important;
      }

      .d-flex {
        display: flex !important;
        font-size: 14px;
        white-space: nowrap;

        .active {
          a {
            color: $btn-color !important;
            font-weight: 600;
          }
        }
      }
    }
  }
}
