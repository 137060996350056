.cardwrapper-section {
  .galleryview-section {
    cursor: pointer;
  }
  .title-details {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .text-headers {
      margin-top: 0;
    }
  }

  .gallery-view {
    display: flex;
    flex-wrap: wrap;
    img {
      width: 200px;
      margin-right: 10px;
      margin-bottom: 25px;
      height: 240px;
      object-fit: fill;
      cursor: pointer;
    }
  }
}

.galleryview-section {
  .view-card {
    // padding: 10px;
    background: #ffffff;
    border: 0.5px solid #919191;
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: fit-content;
    .select-tab {
      width: 120px;
    }
    .imagelist {
      border: 2px solid transparent;
      padding: 6px;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }

    .view-icon {
      font-size: 28px;
    }
    .list-icon {
      background-color: grey;
      color: white;
      padding: 2px;
      border-radius: 4px;
    }
    .active {
      border: 2px solid #ff0026;

      .view-icon {
        color: #ff0026;
      }

      .list-icon {
        background: #ff0026;
      }
    }
  }
}
