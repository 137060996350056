@import "../../Styles/variables";
@import "../../Styles/mixin";
.tab {
  .tab-btn {
    font-size: 18px;
    color: $contentColor;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 25px;
    letter-spacing: 0.07em;
    padding: 8px 20px;
    border-bottom: 2px solid transparent;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    position: relative;
  }

  .active {
    


      color: #0052a7;
      border-radius: 5px 5px 0 0;

      border-bottom: 3px solid #0052a7;
      background: #f8f8f8;

  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.tab-block {
  @include d-flex;
  margin-bottom: 40px;
  .tab {
    margin-right: 40px;
  }
}
