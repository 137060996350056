@import '../../Styles/variables';
.Stepper {
  .primary-section{
    float: right !important;
  }
  .secondary-section{
    float: left !important;
  }
  .tab {
    border-bottom: 3px solid rgba(224, 224, 224, 1);
    margin-bottom: 24px;
    button{
      border: 3px solid $white;
      background-color: unset;
      font-size: 18px;
      font-weight: 600;
      border-radius: none;
      line-height: 25px;
    }
    .btn-space{
      margin-left: 40px;
    }
    .primary-line {
      border-bottom: 3px solid $red;
      color: rgba(182, 30, 37, 1) !important;
    }
    .white-line {
      border: 3px solid white;
    }
  }
  
  .card-section {
    .form-head {
      color: rgba(164, 6, 6, 1);
      font-family: Nunito Sans;
      font-size: 26px;
      font-weight: 600;
      line-height: 35px;
    }
    .line-section {
      display: flex;
    }
  }
}
